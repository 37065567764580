<template>
  <div class="results-list__item__row">
    <div class="results-list__item__location">
      <i class="fas fa-location-dot"></i>&nbsp;
      {{ city !== hotelCity ? hotelCity + " &middot; " : "" }}
      <span v-if="this.distance >= 1000">
        {{
          $t("distanceFromCityLarge", {
            km: formatDecimals(distance / 1000),
            city: city
          })
        }}
      </span>
      <span v-else>
        {{
          $t("distanceFromCity", {
            m: distance,
            city: city
          })
        }}
      </span>
      (<span style="text-decoration: underline; cursor: pointer">{{
        $t("showOnMap")
      }}</span
      >)
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationLabel",
  props: {
    distance: Number,
    hotelCity: String,
    city: String
  }
};
</script>
