<template>
  <div>
    <span class="search__results__filter__title">{{ $t("guestReviews") }}</span>
    <span
      class="c-button--filter--score--red"
      :class="{ selected: score.five }"
      @click="toggleScoreFilter('five')"
    >
      ∞
    </span>
    <span
      class="c-button--filter--score--orange"
      :class="{ selected: score.six }"
      @click="toggleScoreFilter('six')"
    >
      6
    </span>
    <span
      class="c-button--filter--score--medium-green"
      :class="{ selected: score.seven }"
      @click="toggleScoreFilter('seven')"
    >
      7
    </span>
    <span
      class="c-button--filter--score--green"
      :class="{ selected: score.eight }"
      @click="toggleScoreFilter('eight')"
    >
      8
    </span>
    <span
      class="c-button--filter--score--dark-green"
      :class="{ selected: score.nine }"
      @click="toggleScoreFilter('nine')"
    >
      9+
    </span>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      score: {
        five: true,
        six: true,
        seven: true,
        eight: true,
        nine: true
      }
    };
  },
  emits: ["ratingChanged"],
  methods: {
    toggleScoreFilter(score) {
      let isAlltrue = true;

      Object.keys(this.score).forEach((key) => {
        if (this.score[key] === false) {
          isAlltrue = false;
        }
      });

      if (isAlltrue) {
        Object.keys(this.score).forEach((key) => {
          this.score[key] = false;
        });
      }

      this.score[score] = !this.score[score];
      this.$emit("ratingChanged", this.score);
    }
  }
};
</script>

<style lang="scss" scoped></style>
