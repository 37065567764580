import mapboxgl from "!mapbox-gl";
import CommonHelper from "@/services/common.helper";
import HotelHelper from "@/services/hotel.helper.js";

const getLayer = (id, source) => {
  return {
    id: id,
    type: "circle",
    source: source,
    paint: {
      "circle-color": "#4264fb",
      "circle-radius": 8,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff"
    },
    metadata: {
      isCustom: true
    }
  };
};
const getSource = (hotelMarkers) => {
  return {
    type: "geojson",
    data: {
      isCustom: true,
      type: "FeatureCollection",
      features: hotelMarkers
    }
  };
};

const getMarkerFromHotel = (hotel) => {
  return {
    type: "Feature",
    id: `hotelMarker-${hotel.hotelId}`,
    properties: {
      hotelId: hotel.hotelId
    },
    geometry: {
      type: "Point",
      coordinates: [hotel.longitude, hotel.latitude]
    }
  };
};
const getPopupDescription = (selectedHotel, $t) => {
  let description = `<div class="c-map-popup">
  <h6  class='title is-6 mb-1'>${selectedHotel.name}</h6>`;

  if (selectedHotel.stars > 0) {
    let stars = selectedHotel.stars;
    description += `<div class="results-list__item__stars">`;

    for (let i = 1; i <= Math.floor(stars); i++) {
      description += `<i class="fas fa-star"></i>`;
    }
    if (stars % 1 > 0) {
      description += `<i class="fas fa-star-half"></i>`;
    }
    description += `</div>`;
  }
  let lowestOffer = HotelHelper.getPriceSummary(
    selectedHotel?.suppliers
  ).lowestOffer;

  if (selectedHotel.thumbnail) {
    description += `<div class='c-map-popup--image'><img width="200" src="${selectedHotel.thumbnail}" /></div>`;
  }
  description += `<div class='c-map-popup--description'>`;
  if (lowestOffer) {
    description += `
      ${$t("priceFrom")}: ${CommonHelper.formatPrice(lowestOffer.price)} `;
  } else {
    description += `${$t("noPrices")}`;
  }
  description += `</div>`;
  description += `<a href="${selectedHotel.link}">${$t("viewHotel")}`;
  description += `</div>`;
  return description;
};

const getMarkerFromSearch = (location) => {
  let marker = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [location.longitude, location.latitude]
    }
  };
  return marker;
};
const getNewMap = (mapContainer, showControls = true) => {
  let map = new mapboxgl.Map({
    container: mapContainer,
    // eslint-disable-next-line no-undef
    style: process.env.VUE_APP_MAPBOX_STYLE,
    zoom: 15
  });
  if (showControls) {
    map.addControl(new mapboxgl.NavigationControl());
  }
  return map;
};

const clearMap = (map, layers, sources) => {
  let customLayers = layers.filter((l) => l.metadata && l.metadata.isCustom);

  customLayers.forEach((l) => {
    map.removeLayer(l.id);
  });

  Object.keys(sources).forEach((sourceId) => {
    let source = sources[sourceId];

    if (source.data && source.data.isCustom) {
      map.removeSource(sourceId);
    }
  });
};

export default {
  getLayer,
  getMarkerFromHotel,
  getPopupDescription,
  getMarkerFromSearch,
  getSource,
  getNewMap,
  clearMap
};
