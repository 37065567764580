<template>
  <div class="main-login">
    <div class="login-wrapper p-5">
      <div v-if="isFetchingCompanyData" class="isLoading">
        <h3 class="title is-3 has-text-centered p-3">
          {{ $t("loginPage.accountDataFetching") }}
          <br />
          <br />
          <img width="50" height="60" src="@/assets/images/loader.svg" />
        </h3>
      </div>
      <form @submit.prevent="Login()" v-else>
        <div class="columns is-multiline">
          <div class="field column is-full">
            <h1 class="is-h1 title">{{ $t("loginPage.header") }}</h1>
          </div>
          <div class="field column is-full">
            <label class="firstname">{{ $t("username") }}</label>
            <div class="control">
              <input
                class="input"
                name="username"
                v-model="loginData.userName"
                required
                autocomplete="username"
                type="text"
                placeholder=""
              />
            </div>
          </div>
          <div class="field column is-full">
            <label class="password">{{ $t("password") }}</label>
            <div class="control">
              <input
                class="input"
                name="password"
                v-model="loginData.password"
                required
                autocomplete="current-password"
                type="password"
                placeholder=""
              />
            </div>
          </div>
          <div class="field column is-full">
            <div class="control has-text-right">
              <img
                width="30"
                height="30"
                v-if="isLoading"
                src="@/assets/images/loader.svg"
                class="c-loading-icon"
              />

              <button type="submit" class="c-button is-primary is-medium">
                {{ $t("login") }}
              </button>
              <br />
              <br />
              <a
                :href="passwordUrl"
                class="pt-3"
                target="_blank"
                v-if="passwordUrl"
                >{{ $t("forgot.password") }}</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <notification-message
      :level="'warning'"
      :title="notificationTitle"
      :message="notificationMessage"
      :show="showNotification"
      @toggle="toggleLoginError"
    />
  </div>
</template>

<script>
import NotificationMessage from "./components/shared/NotificationMessage.vue";
import CommonHelper from "@/services/common.helper.js";

export default {
  components: { NotificationMessage },
  name: "Login",
  data() {
    return {
      showNotification: false,
      notificationTitle: "",
      notificationMessage: "",
      isLoading: false,
      isFetchingCompanyData: false,
      passwordUrl: "",
      loginData: {
        userName: "",
        password: ""
      }
    };
  },
  async mounted() {
    await this.getPasswordUrl();
  },
  methods: {
    async Login() {
      const loginUrl = `${this.AVAILABILITY_API_URL}/authentication/login`;
      this.isLoading = true;

      let { data, status } = await this.axios.post(loginUrl, {
        userName: this.loginData.userName,
        password: this.loginData.password
      });

      // If the status code is 200 (HTTP OK)
      if (status === 200) {
        this.isLoading = false;
        this.token = data;
        await CommonHelper.saveToken(this.token);
        // Navigate to the MFA page
        this.$router.push({
          name: this.PAGES.MFA
        });
      } else if (status === 401) {
        this.isLoading = false;
        if (data === "PASSWORD_EXPIRED") {
          this.notificationMessage = this.$t("PASSWORD_EXPIRED");
        } else {
          this.notificationMessage = this.$t("errorLoggingInCredentials");
        }
        this.showNotification = true;
      } else {
        this.isLoading = false;
        this.notificationMessage = this.$t("errorLoggingIn");
        this.showNotification = true;
      }
    },

    async getPasswordUrl() {
      const url = `${this.AVAILABILITY_API_URL}/reservation/portal-init`;
      const { data } = await this.axios.get(url);
      this.passwordUrl = data.resetPasswordUrl;
    },
    toggleLoginError(e) {
      this.showNotification = e;
    }
  }
};
</script>

<style lang="scss">
.main-login {
  margin: 0;
  height: 100vh;
  background: center no-repeat;
  background-image: var(--brand-background-image);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrapper {
  background: white;
  margin: 0 12px;
  display: flex;
  box-shadow: -10px -2px 10px 3px #36363661;
  align-self: center;
  max-width: 600px;
  border-radius: 8px;
}

.logo--big {
  position: absolute;
  right: 0;
  height: 60px;
  top: 50%;
  margin-top: -30px;
}

.control .c-loading-icon {
  position: relative;
  top: 8px;
  right: 16px;
}
</style>
