<template>
  <div v-if="images">
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="lightBoxVisible"
      :imgs="lightboxImgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <div class="detail-photos">
      <div
        v-for="(picture, $index) in images.slice(0, 3)"
        :key="$index"
        v-bind:style="{
          'background-image': `url(${picture.link})`
        }"
        @click="showLightbox"
        :class="`detail-photos__photo-box-${$index + 1}`"
      ></div>
    </div>
    <div class="detail-photos__label" @click="showLightbox">
      <h6>
        {{ $t("viewAllPhotosText", { count: lightboxImgs?.length }) }}
      </h6>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox
  },
  name: "HotelPictures",
  props: {
    hotelName: String,
    hero: Object,
    pictures: Object
  },
  created() {
    this.lightboxImgs = [];

    let images = this.images.map((element, index) => {
      return {
        src: element.link,
        title: `${this.hotelName}  ${index + 1}/${this.images.length}`
      };
    });

    this.lightboxImgs = images;
  },
  data: function () {
    return {
      index: 0,
      lightboxImgs: [],
      lightBoxVisible: false
    };
  },
  methods: {
    showLightbox() {
      this.lightBoxVisible = true;
    },

    handleHide() {
      this.lightBoxVisible = false;
    }
  },
  computed: {
    images() {
      let allPictures = [];
      for (let key in this.pictures) {
        if (this.pictures[key]) {
          allPictures.push(...this.pictures[key]);
        }
      }
      allPictures.unshift(this.hero);
      return allPictures;
    }
  }
};
</script>

<style lang="sass" scoped></style>
