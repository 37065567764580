<template>
  <div style="cursor: pointer">
    <template v-if="location && variables">
      <div class="columns">
        <div class="column has-text-centered">
          <i class="fa fa-calendar"></i>
          {{ dateRange.startDateString }} - {{ dateRange.endDateString }}
        </div>
        <div class="column has-text-centered">
          <i class="fa fa-moon"> </i>
          {{ variables.nights }}
          &nbsp;&nbsp;
          <i class="fa fa-person"></i>
          {{ variables.adults }}
          &nbsp;&nbsp;
          <i class="fa fa-tent"></i>
          {{ variables.rooms }}
          &nbsp;&nbsp;
          <span :title="hotelCount + '  hotels'">
            <i class="fa fa-hotel"></i>
            {{ hotelCount }}
          </span>
        </div>
        <div class="column has-text-centered">
          {{ location?.name }},
          {{ location?.country }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CommonHelper from "@/services/common.helper";
export default {
  name: "SearchSummary",
  props: {
    variables: {
      type: Object,
      default: () => {}
    },
    hotelCount: {
      type: Number,
      default: -1
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dateRange: {
        startDateString: "",
        endDateString: "",
        nights: 1
      }
    };
  },
  watch: {
    variables: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.setDateRange();
      }
    }
  },
  mounted() {
    this.setDateRange();
  },
  methods: {
    setDateRange() {
      let dateRange = CommonHelper.getDateRange(
        this.variables.checkin,
        this.variables.nights
      );

      this.dateRange = dateRange;
    }
  }
};
</script>

<style lang="scss" scoped></style>
