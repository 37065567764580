<template>
  <div class="columns is-multiline">
    <div class="column is-half">
      <label class="radio" v-for="option in dateRangeTypes" :key="option">
        <input
          @change="dateRangeChanged()"
          type="radio"
          name="dateRangeType"
          :required="true"
          v-model="dateRangeType"
          :value="option"
        />
        {{ $t("dateTimeSelection." + option) }}
      </label>
      <br />
      <br />
      <label for="startRoom">
        {{
          isMultiDay
            ? $t("dateTimeSelection.startDate")
            : $t("dateTimeSelection.date")
        }}
      </label>
      <br />
      <span>{{ formattedStartDate }}</span>

      <template v-if="isMultiDay">
        <br />
        <br />
        <label for="endDate"> {{ $t("dateTimeSelection.endDate") }} </label>
        <br />
        <span>{{ formattedEndDate }}</span>
      </template>
    </div>

    <div class="column is-half">
      <Datepicker
        v-if="isMultiDay"
        v-model="dateRange"
        :minDate="new Date()"
        @update:modelValue="handleDate"
        :enableTimePicker="false"
        :range="true"
        inline
        autoApply
        locale="language"
      />
      <Datepicker
        v-else
        v-model="startDate"
        :minDate="new Date()"
        @update:modelValue="handleDate"
        :enableTimePicker="false"
        :range="false"
        inline
        autoApply
        locale="language"
      />
    </div>
    <template v-if="!isMultiDay">
      <div class="column is-half">
        <label for="startRoom">
          {{ $t("dateTimeSelection.startTime") }}
        </label>
        <input type="time" @change="updateValue()" v-model="startTime" />
      </div>
      <div class="column is-half">
        <label for="startRoom"> {{ $t("dateTimeSelection.endTime") }} </label>
        <input type="time" @change="updateValue()" v-model="endTime" />
      </div>
    </template>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

import moment from "moment";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      dateRangeType: "Dag",
      dateRangeTypes: ["Dagdeel", "Dag", "Meerdere dagen"],
      startDate: moment().add("1", "days").toDate(),
      endDate: moment().add("1", "days").toDate(),
      dateRange: [
        moment().add("1", "days").toDate(),
        moment(this.arrivalDate).add("1", "days").toDate()
      ],
      formattedStartDate: moment().add("1", "days").format("DD-MM-YYYY"),
      formattedEndDate: "",
      date: null,
      startTime: "09:00",
      endTime: "17:00"
    };
  },
  computed: {
    isMultiDay() {
      return this.dateRangeType == "Meerdere dagen";
    },
    isPartialDay() {
      return this.dateRangeType == "Dagdeel";
    },
    isDay() {
      return this.dateRangeType == "Dag";
    }
  },
  methods: {
    dateRangeChanged() {
      if (this.isPartialDay) {
        this.startTime = "12:00";
        this.endTime = "16:00";
      } else if (this.isDay) {
        this.startTime = "09:00";
        this.endTime = "17:00";
      } else {
        this.startTime = null;
        this.endTime = null;
      }
      this.updateValue();
    },
    handleDate(data) {
      if (this.isMultiDay) {
        this.startDate = data[0];
        this.endDate = data[1];
        this.formattedStartDate = moment(data[0]).format("DD-MM-YYYY");
        this.formattedEndDate = moment(data[1]).format("DD-MM-YYYY");
      } else {
        this.startDate = data;
        this.endDate = data;
        this.formattedStartDate = moment(data).format("DD-MM-YYYY");
      }
      this.updateValue();
    },
    updateValue() {
      const emitValue = {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        dateRangeType: this.dateRangeType
      };

      this.$emit("updated-value", emitValue);
    }
  }
};
</script>

<style lang="sass" scoped></style>
