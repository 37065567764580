export default {
  "MfaPage": {
    "accountDataFetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw accountgegevens worden opgehaald"])},
    "checkMail2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw email voor de zojuist gestuurde authenticatie code"])},
    "errorInvalidTwofactorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code onjuist"])},
    "errorTwoFactorCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is te vaak onjuist ingevoerd."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Authenticatie"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ter beveiliging is er een code verstuurd naar het e-mailadres waarmee u bent ingelogd. Vul deze code hieronder in om in te loggen."])},
    "mfaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "toLoginForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar inlogscherm"])},
    "twoFactorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Code"])}
  },
  "PASSWORD_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is verlopen. Gebruik de link onder de login-knop om een nieuw wachtwoord in te stellen"])},
  "ReservationForm": {
    "AddFlightLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg vlucht toe"])},
    "AddReturnFlightLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg retourvlucht toe"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "PREMIUM_ECONOMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Economy"])},
    "addAGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een gast toe"])},
    "addAPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een passagier toe"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middag"])},
    "arrivalAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomst"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Class"])},
    "changesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen mogelijk"])},
    "checkedLuggage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeken inclusief ruimbagage"])},
    "cityReservationIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats een verzoek en wij gaan kijken wat we kunnen betekenen. Hoe    meer informatie je ons kan gevonden hoe gerichter wij kunnen zoeken.    Denk hierbij bijvoorbeeld aan ligging (in de buurt van een station,    afrit, evenementlocatie, stadscentrum) en andere voorkeuren    (ontbijt, diner, parkeerplaatsen). Voer dit in in het    opmerkingenveld."])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
    "departureAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrek"])},
    "departureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrekdatum"])},
    "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het later opnieuw of neem contact met ons op als het probleem blijft"])},
    "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avond"])},
    "find": {
      "businessunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek BUSINESS_UNIT"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek werknemer"])}
    },
    "findBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een BUSINESS_UNIT"])},
    "findEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een werknemer"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornamen"])},
    "flightClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisklasse"])},
    "flightNotesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg hier belangrijke informatie toe voor het boeken van de vlucht(en). Indien gewenst kunt u hier ook specifieke vertrektijden of vluchtnummers toevoegen."])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier wordt opgehaald"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochtend"])},
    "multiLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meerdere steden"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationaliteit (op het paspoort)"])},
    "noGuestsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen gasten toegevoegd"])},
    "noPassengersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen passagiers toegevoegd"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
    "notesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer hier informatie in over uw verzoek, zoals duur, meerdere periodes of andere informatie die van belang kan zijn"])},
    "oneWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkele reis"])},
    "passportExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paspoort geldig tot"])},
    "passportNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentnummer"])},
    "personalReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is een reservering voor u persoonlijk, uw gegevens worden automatisch opgeslagen bij de reservering."])},
    "personalReservationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke reservering"])},
    "requestCityReservation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stadsreservering aanvragen voor ", _interpolate(_named("city"))])},
    "requestReservation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reservering aanvragen bij ", _interpolate(_named("name"))])},
    "reservationBase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vraag een reservering aan voor ", _interpolate(_named("count")), " nacht, van ", _interpolate(_named("startDate")), " tot en  met ", _interpolate(_named("endDate"))]), _normalize(["Vraag een reservering aan voor ", _interpolate(_named("count")), " nachten, van ", _interpolate(_named("startDate")), " tot en  met ", _interpolate(_named("endDate"))])])},
    "returnFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrekdatum terugvlucht"])},
    "roundTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourvlucht"])},
    "savedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opgeslagen"])},
    "savedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij nemen contact met u op"])},
    "savedPassportdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw paspoortgegevens zijn opgeslagen"])},
    "savedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw aanvraag is geplaatst"])},
    "selectedGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde gasten"])},
    "selectedPassengers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde passagiers"])},
    "unknownEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunt u de gezochte werknemer niet vinden? Dan kunt u deze hiernaast invoeren."])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg toe"])},
  "adultsWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " volwassenen"]), _normalize([_interpolate(_named("count")), " volwassene"]), _normalize([_interpolate(_named("count")), " volwassenen"])])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
  "allDatesCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle geselecteerde data zijn geannuleerd"])},
  "amenities": {
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
    "gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportschool"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorzieningen"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunchpakketten"])},
    "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergaderzalen"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeren in het hotel"])},
    "pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwembad"])},
    "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 uurs receptie"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
  },
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassen"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je het zeker?"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar home"])},
  "backToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar het zoekresultaat"])},
  "basedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Op basis van een verblijf van ", _interpolate(_named("count")), " nacht, check-in op ", _interpolate(_named("startDate")), " en check-out op ", _interpolate(_named("endDate")), ". Prijsindicatie is per persoon."]), _normalize(["Op basis van een verblijf van ", _interpolate(_named("count")), " nachten, check-in op ", _interpolate(_named("startDate")), " en check-out op ", _interpolate(_named("endDate")), ". Prijsindicatie is per persoon."])])},
  "basedOnChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt dit wijzigen in het reserveringsscherm"])},
  "booker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeker"])},
  "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekingsdatum"])},
  "bookingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekingsvoorwaarden"])},
  "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt"])},
  "breakfastAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt inbegrepen"])},
  "breakfastNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen ontbijt inbegrepen"])},
  "businessunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["business unit"])},
  "cateringOption": {
    "Borrel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrel"])},
    "Diner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
    "Frisdranken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisdranken"])},
    "Koffie / Thee / Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koffie / Thee / Water"])},
    "Lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])}
  },
  "cityReservation": {
    "noneFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We hebben niets gevonden in ", _interpolate(_named("name")), "..."])},
    "notFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niet gevonden wat je zocht in ", _interpolate(_named("name")), "?"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe verzoek"])},
    "placeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats dan een stadsverzoek en dan gaan wij voor je aan de slag"])},
    "placeRequestNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je een stadsverzoek doet, dan kunnen wij kijken of we een alternatief kunnen bieden"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "collapseOfferless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg hotels zonder aanbod"])},
  "comingNightNoPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U zoekt een kamer voor vannacht; het kan zijn dat u geen prijzen   ziet bij hotels die wellicht wel beschikbaarheid hebben. U kunt wel een   reserveringsaanvraag indienen. Wij proberen dan om alsnog een boeking   te plaatsen bij het aangevraagde hotel. Mocht dit niet lukken stellen we   een alternatief voor indien beschikbaar."])},
  "complexBooking": {
    "breakfastRefundableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u reserveren met ontbijt en refundable?"])},
    "changeDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aanpassen"])},
    "chosenData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekozen data"])},
    "chosenFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft gekozen voor"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder"])},
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt ook boeken voor een langere periode met tussenpozen. Dan kunnen we echter geen prijzen of beschikbaarheid tonen. U kunt tevens niet voor individuele personen aparte dagen reserveren."])},
    "launchModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeken voor langere periodes met tussenpozen"])},
    "modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langdurige boeking maken"])},
    "modalHeaderStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de eerste en laatste verblijfdatum."])},
    "modalHeaderStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de dagen"])},
    "modalHeaderStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de gewenste verblijfdata"])},
    "modalHeaderStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer uw invoer"])},
    "step1Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de eerste en laatste verblijfdatum.   Met de pijltjes bovenin kunt u naar de volgende maand.    Door op de maand of het jaar te klikken kunt u een andere maand of    jaar selecteren."])},
    "step2Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de dagen waarop de gast wil inchecken. In de volgende stap kunt u uitzonderingen toevoegen."])},
    "step3Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze dagen hebben wij nu geselecteerd, u kunt hier nu eventuele uitzonderingen aangeven. Denk aan feestdagen of vrije dagen waarop de gast niet in het hotel zal verblijven. U kunt ook extra dagen toevoegen."])},
    "step4Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de data die u hebt aangegeven."])}
  },
  "confirmCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig annuleringsverzoek"])},
  "confirmCancellationRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het verzoek is ingediend. U ontvangt een bevestiging per e-mail."])},
  "confirmCancellationRequestedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzoek ingediend"])},
  "confirmationTextCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u een annulering wil aanvragen? Let op: dit is een verzoek en niet een definitieve annulering. U ontvangt een bevestiging per e-mail."])},
  "costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten"])},
  "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig"])},
  "dateRangeTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden. Probeer een kleine datumrange op te vragen"])},
  "dateTimeSelection": {
    "Dag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
    "Dagdeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagdeel"])},
    "Meerdere dagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meerdere dagen"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindtijd"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "startDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum vanaf"])},
    "startDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum tot"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starttijd"])}
  },
  "datepickerTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip: Klik in de datumselectie op een jaartal of maand (bovenin) en scroll om een specifieke maand of jaartal gemakkelijk te kiezen"])},
  "dayPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagdeel vertrek"])},
  "directBookingNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: genoemde prijzen zijn mogelijk gebasseerd op een hypothetische verblijfsperiode. Controleer deze voor u de reservering plaatst"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afstand"])},
  "distanceFromCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "m van centrum ", _interpolate(_named("city"))])},
  "distanceFromCityAbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van het centrum"])},
  "distanceFromCityLarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("km")), "km van centrum ", _interpolate(_named("city"))])},
  "doYouWishToMakeACityReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je een stads- of locatiereservering maken?"])},
  "editReservationMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig"])},
  "enterDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een datumrange"])},
  "enteredAllWishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Heb je al je wensen ingevoerd? Plaats dan je aanvraag en wij gaan  aan de slag!"])},
  "error": {
    "businessunitMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een BUSINESS_UNIT"])},
    "customFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de velden gemarkeerd met een * in"])},
    "employeeMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een werknemer"])},
    "genericIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgende gegevens zijn niet correct ingevoerd of missen"])},
    "genericTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de ingevoerde data"])},
    "locationMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een locatie"])},
    "noLegs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg vluchten toe"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een aanbieding"])}
  },
  "errorLoggingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het inloggen, probeer het later opnieuw. Dit is een systeemfout en ligt niet aan uw inloggegevens"])},
  "errorLoggingInCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het inloggen, controleer uw inloggegevens"])},
  "expedia": {
    "AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airconditioning aanwezig"])},
    "ACCESSIBLE_ROLL_IN_SHOWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll-in Shower "])},
    "AIRPORT_TRANSPORTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoer naar de luchthaven"])},
    "ALL_INCLUSIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All inclusive"])},
    "ARCADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arcade"])},
    "BABYSITTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderoppas"])},
    "BALCONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balkon"])},
    "BAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
    "BARBECUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbecue"])},
    "BATHROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badkamer"])},
    "BEDROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaapkamer"])},
    "BOWLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bowling"])},
    "BREAKFAST_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt beschikbaar"])},
    "BREAKFAST_INCLUDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontbijt inclusief"])},
    "BUSINESS_SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakelijke diensten"])},
    "CHILDREN_CLUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids club"])},
    "CRIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieg"])},
    "DRYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droger"])},
    "ELECTRIC_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplaadpunt voor elektrische voertuigen"])},
    "ELEVATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lift"])},
    "ENTERTAINMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "EXTRA_BED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra bed"])},
    "FIREPLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open haard"])},
    "FITNESS_EQUIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitnessruimte"])},
    "FOOD_AND_DRINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eten & drinken"])},
    "FREE_PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis parkeren"])},
    "FRONTDESK_24_HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontdesk 24-uur"])},
    "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boodschappen"])},
    "HEATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwarming"])},
    "HOT_TUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot tub"])},
    "HOUSEKEEPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huishouding"])},
    "INTERNET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
    "KIDS_POOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderzwembad"])},
    "KITCHEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keuken"])},
    "LAUNDRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasfaciliteiten"])},
    "LIVING_AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woonkamer"])},
    "MICROWAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnetron"])},
    "MINI_GOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minigolf"])},
    "MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer..."])},
    "NO_SMOKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet-rokerskamer"])},
    "OUTDOOR_GRILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buiten grill"])},
    "OUTDOOR_SPACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buitenruimte"])},
    "PARKING_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeerplaatsen beschikbaar"])},
    "PARKING_INCLUDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeerplaatsen inclusief"])},
    "PATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patio"])},
    "PETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisdieren toegestaan"])},
    "PLAYGROUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeltuin "])},
    "POOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwembad"])},
    "RESTAURANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "ROOM_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roomservice"])},
    "SOUNDPROOF_ROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geluidsgeisoleerde ruimte"])},
    "SPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spa"])},
    "SPECIAL_DEAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciale deal"])},
    "TENNIS_COURT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennisbaan"])},
    "TOYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speelgoed"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])},
    "WASHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasmachine"])},
    "WASHER_AND_DRYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasmachine en droger"])},
    "WATERSLIDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterglijbaan"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegankelijkheid"])},
    "activitiesNearby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de buurt"])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badkamers"])},
    "beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strand"])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaapkamer"])},
    "businessServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakelijke diensten"])},
    "childrenAndExtraBedPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderen & extra bedden"])},
    "cleanlinessAndSafety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netheid "])},
    "climateControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klimaatbeheersing"])},
    "conveniences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemak"])},
    "dining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
    "entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "familyFriendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familievriendelijkheid"])},
    "foodAndDrink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eten & drinken"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
    "guestServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten"])},
    "internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keuken"])},
    "knowBeforeYouGo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handig om te weten!"])},
    "langsSpoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesproken talen"])},
    "laundry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasdienst"])},
    "livingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woonkamers"])},
    "locationHighlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoogtepunten"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer..."])},
    "outdoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buitenruimte"])},
    "outdoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buitenruimte"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkeren"])},
    "petPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisdieren"])},
    "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisdieren"])},
    "poolspa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwembad & spa"])},
    "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiligheid"])},
    "servicesAndConveniences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensten & gemak"])},
    "ski": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spa"])},
    "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciale instructies"])},
    "suitabilityAccessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegankelijkheid"])},
    "thingsToDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te doen in de omgeving"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkplekken"])}
  },
  "facilitiesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faciliteiten"])},
  "facilityOption": {
    "Beamer/Scherm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beamer/Scherm"])},
    "Flipover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flipover"])},
    "Geluidsinstallatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geluidsinstallatie"])},
    "Microfoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microfoon"])}
  },
  "favoriteHotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoriete hotels"])},
  "favoriteHotelsNoHotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier komen uw meest geboekte hotels te staan wanneer er boekingen bekend zijn."])},
  "findAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek luchthaven"])},
  "findLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie zoeken"])},
  "flights": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boek een vlucht"])}
  },
  "forgot.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdag"])},
  "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstig"])},
  "genericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout!"])},
  "greenKeyFilter": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen Green Key accommodaties?"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gast"])},
  "guestReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastenbeoordelingen"])},
  "hasCancelledDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 of meer geselecteerde data zijn geannuleerd"])},
  "hasGreenKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft een Green Key"])},
  "hasGreenKeyExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Key is een internationaal duurzaamheidskeurmerk voor bedrijven in de reactieve- en vrijetijdsbranche en zakelijke markt. De accommodaties met een Green Key-certificaat doen veel op het gebied van duurzaamheid, zonder dat hun gasten inleveren op comfort en kwaliteit. Zij gaan hierbij een stap verder dan de normale wet- en regelgeving vereist.  "])},
  "hasGreenKeyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze accomodatie heeft een Green Key"])},
  "hasNoGreenKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft geen Green Key"])},
  "hideCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg geannuleerd"])},
  "hotelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel adres"])},
  "hotelAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelvoorzieningen"])},
  "hotelLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelgegevens worden opgehaald"])},
  "hotelsLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels worden opgehaald"])},
  "inOutCheckTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In- & uitchecktijden"])},
  "inTheNeighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de buurt"])},
  "loadingCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hotels gevonden"])},
  "loadingStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "% geladen"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "loginPage": {
    "accountDataFetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw accountgegevens worden opgehaald"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen op de Portal"])}
  },
  "loginStatus": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "wantToLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u uitloggen?"])},
    "yesLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, uitloggen"])}
  },
  "meetingReservation": {
    "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de ingevoerde waardes en probeer het  opnieuw of neem contact met ons op als het probleem blijft"])},
    "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ging iets mis"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boek een vergaderarrangement"])},
    "iNeedHotelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb hotelkamers nodig"])},
    "needHotelRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelkamers nodig?"])},
    "numberDuoRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal tweepersoonskamers"])},
    "numberSingleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal eenpersoonskamers"])},
    "roomLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaalindeling"])},
    "savedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij nemen contact met u op"])},
    "savedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw aanvraag is geplaatst"])}
  },
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie"])},
  "nav": {
    "bookingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekingsoverzicht"])},
    "flightForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vluchten"])},
    "meetingReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergaderarrangement"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])}
  },
  "nightsWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " nachten"]), _normalize([_interpolate(_named("count")), " nacht"]), _normalize([_interpolate(_named("count")), " nachten"])])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "noGoBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee, ga terug"])},
  "noHotelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor dit hotel is er geen content gevonden"])},
  "noHotelsFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander uw zoekopdracht"])},
  "noHotelsFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen hotels gevonden"])},
  "noPriceFoundRequestAnyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen beschikbaarheid gevonden bij aanbieders die wij hebben opgenomen in de vergelijking. U kunt daarom momenteel geen reservering aanvragen voor dit hotel. Wij raden u aan om een ander hotel aan te vragen. U kunt bij vragen contact opnemen met onze Corporate Travel Consultants."])},
  "noPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen prijzen gevonden"])},
  "noReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet beoordeeld"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer hier extra wensen of toelichting op tijden in"])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets gevonden"])},
  "offerIsNotRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbod is niet refundable"])},
  "offerIsRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbod is refundable"])},
  "offerNotOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze aanbieding is online niet beschikbaar. Echter is het voor ons bijna altijd mogelijk om onder deze voorwaarden te boeken."])},
  "offerNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanvraag"])},
  "otherOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere opties"])},
  "passportNumberTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul dit alleen in als u het wil wijzigen"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalmethoden"])},
  "perNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per nacht"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
  "popularAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populaire voorzieningen"])},
  "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanaf"])},
  "profile": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de gegevens exact zo in als op het paspoort"])},
    "metamenuLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer paspoortgegevens"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig paspoortgegevens"])}
  },
  "projectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projectnummer"])},
  "rebook": {
    "doubleWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: u hebt opties gekozen die mogelijk een dubbele boeking    opleveren. Controleer bij de reservering de datum"])},
    "rebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw boeken"])},
    "sameBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfde BUSINESS_UNIT"])},
    "sameDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfde datum"])},
    "sameGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfde gast"])}
  },
  "recommended.hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen"])},
  "recommendedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen hotels zijn refundable <br /> en inclusief ontbijt"])},
  "refundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable"])},
  "refundableFilter": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable?"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "requestCancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Annuleringsverzoek indienen voor ", _interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])},
  "requestCancellationAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer alle data"])},
  "requestCancellationAllEmployees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer alles"])},
  "requestCancellationSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleer geselecteerde data"])},
  "requestReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservering aanvragen"])},
  "reservationOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveringsoverzicht"])},
  "reservationOverviewLimitedResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn veel resultaten teruggekomen en mogelijk niet alle uit de geselecteerde tijdsperiode, vernauw deze indien u de gezochte reservering of boeking niet terug kunt vinden. "])},
  "reservationOverviewMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveringen kunt u wijzigen of annuleren in dit boekingsoverzicht of per e-mail via zakelijk&#64;lookingforbooking.com"])},
  "reservationoverview": {
    "externalBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze boeking is buiten het portaal om gemaakt en kan niet opnieuw worden geboekt in het portaal."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekingen worden opgehaald"])},
    "omniSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op werknemer, stad of hotelnaam"])},
    "startdate_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in tot"])},
    "startdate_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in vanaf"])}
  },
  "resultsPage": {
    "budget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het budget per nacht is EUR ", _interpolate(_named("budget"))])},
    "countHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hotelCount")), " hotels in ", _interpolate(_named("name"))])},
    "priceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle genoemde prijzen op deze pagina zijn per nacht, per persoon en indicatief."])},
    "sortDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afstand tot centrum"])},
    "sortRecommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbevolen + afstand tot centrum"])},
    "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortering"])}
  },
  "reviewCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 beoordelingen"]), _normalize(["1 beoordeling"]), _normalize([_interpolate(_named("numberOfReviews")), " beoordelingen"])])},
  "reviews": {
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldoende"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slecht"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeer matig"])},
    "reallyGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeer goed"])},
    "terrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slecht"])},
    "veryGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heel goed"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamervoorzieningen"])},
  "roomLayouts": {
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok"])},
    "Boardroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadzaal"])},
    "Cabaret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabaret"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anders"])},
    "School": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "Square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierkant"])},
    "Theater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theater"])},
    "U-Shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U-vorm"])}
  },
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaterdag"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
  "saveAndLoadPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla op en laad nieuwe prijzen"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
  "searchFilters": {
    "applyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters toepassen"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters wijzigen"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
    "distanceToCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afstand tot centrum"])},
    "distanceToLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tot ", _interpolate(_named("n")), " km"])},
    "distanceToLabelAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch bepalen"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "hotelNameOrChain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op hotelnaam of keten"])},
    "hotelNameOrChainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam of keten"])},
    "numberOfPersons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal personen"])},
    "numberOfPersonsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal kamers"])},
    "numberOfRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamers"])},
    "starCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal sterren"])}
  },
  "searchPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar wilt u heen?"])}
  },
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer alles"])},
  "selectCheckinAndCheckOutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer eerst uw check-in datum en daarna uw check-out datum in de kalender. Wilt u een boeking voor een langere periode met tussenpozen doen? Selecteer dan alleen de eerste nacht."])},
  "selectDatePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een datum of periode"])},
  "selectYourOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer uw aanbieding"])},
  "selectedBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde BUSINESS_UNIT"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vluchtaanvraag versturen"])},
  "serverErrorTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: De server gaf een fout. Probeer het later opnieuw."])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])},
  "showOnMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon op kaart"])},
  "stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verblijf"])},
  "stayPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in & check-out"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinsdag"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
  "viewAllPhotosText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Bekijk 1 foto"]), _normalize(["Bekijk alle ", _interpolate(_named("count")), " foto's"]), _normalize(["Bekijk ", _interpolate(_named("count")), " foto's"])])},
  "viewHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk hotel"])},
  "warningOverBudget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Let op, deze aanbieding overschrijdt uw budget van EUR ", _interpolate(_named("budget")), " per nacht."])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woensdag"])}
}