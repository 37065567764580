<template>
  <div v-if="this.score >= 9" class="details__content__score">
    <span class="pill--dark-green">{{ score }}</span>
    <strong>{{ $t("reviews.reallyGood") }}</strong>
    <template v-if="this.amount > 1">
      ({{ $t("reviewCount", getReviewCount()) }})
    </template>
  </div>

  <div v-else-if="this.score > 7" class="details__content__score">
    <span class="pill--green">{{ score }}</span>
    <strong>{{ $t("reviews.good") }}</strong>
    <template v-if="this.amount > 1">
      ({{ $t("reviewCount", getReviewCount()) }})
    </template>
  </div>

  <div v-else-if="this.score >= 6" class="details__content__score">
    <span class="pill--orange">{{ score }}</span>
    <strong>{{ $t("reviews.average") }}</strong>
    <template v-if="this.amount > 1">
      ({{ $t("reviewCount", getReviewCount()) }})
    </template>
  </div>

  <div v-else-if="this.score >= 1" class="details__content__score">
    <span class="pill--red">{{ score }}</span>
    <strong>{{ $t("reviews.bad") }}</strong>
    <template v-if="this.amount > 1">
      ({{ $t("reviewCount", getReviewCount()) }})</template
    >
  </div>

  <div v-else class="details__content__score">
    <span class="pill--grey">{{ score }}</span>
    <strong>{{ $t("noReviews") }}</strong>
  </div>
</template>

<script>
export default {
  name: "ReviewsSummary",
  props: {
    score: String,
    amount: Number
  },
  methods: {
    getReviewCount() {
      const reviewCount = this.formatNumber(this.amount);
      return { count: this.amount, numberOfReviews: reviewCount };
    }
  }
};
</script>
