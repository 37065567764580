import "@/assets/styles/main.scss";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Vue from "vue";
import VueAxios from "vue-axios";
import * as VueRouter from "vue-router";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "@vuepic/vue-datepicker/dist/main.css";

window.dataLayer = window.dataLayer || [];

function gtag() {
  // eslint-disable-next-line no-undef
  dataLayer.push(arguments);
}
import App from "@/App.vue";
import { mixins } from "@/helpers/mixins.js";
import LandingPage from "@/LandingPage.vue";
import LoginForm from "@/LoginForm.vue";
import MFAForm from "@/MFAForm.vue";
import SSOPage from "@/SSOPage.vue";
import HotelDetail from "@/HotelDetail.vue";
import HotelReservationForm from "@/HotelReservationForm.vue";
import MeetingRoomReservation from "@/MeetingRoomReservation.vue";
import ReservationOverview from "@/ReservationOverview.vue";
import SearchResult from "@/SearchResult.vue";
import UserProfile from "@/UserProfile.vue";
import FlightForm from "@/FlightForm.vue";
import NotificationMessage from "@/components/shared/NotificationMessage.vue";

import i18n from "@/i18n";
import CommonHelper from "@/services/common.helper";
const PAGES = CommonHelper.PAGES;
const routes = [
  { path: "/", component: LoginForm, name: PAGES.Login },
  {
    path: "/sso/:companyOrSessionId/:SessionSecondsValid?",
    component: SSOPage,
    name: PAGES.SSO
  },

  { path: "/mfa", component: MFAForm, name: PAGES.MFA },
  { path: "/userprofile", component: UserProfile, name: PAGES.UserProfile },
  { path: "/flightform", component: FlightForm, name: PAGES.FlightForm },
  { path: "/home", component: LandingPage, name: PAGES.LandingPage },
  {
    path: "/search/:locationPath/:checkin/:nights/:adults/:rooms/:refundable/:greenKey?/:feedbackId?",
    component: SearchResult,
    name: PAGES.SearchResult
  },
  {
    path: "/hotel/:locationPath/:hotelId/:checkin?/:nights?/:adults?/:rooms?/:refundable?/:greenKey?/:feedbackId?/:breakfastIncluded?",
    component: HotelDetail,
    name: PAGES.HotelDetail,
    props: true
  },
  {
    path: "/reservation/:locationPath/:hotelId/:checkin/:nights/:adults/:rooms/:refundable/:greenKey?/:feedbackId?/:breakfastIncluded?",
    props: (route) => ({
      reservationType: "hotel",
      ...route.params
    }),
    name: PAGES.HotelReservationForm,
    component: HotelReservationForm
  },
  {
    path: "/reservation/:locationPath/:hotelId/:checkin?/:nights?/:adults?/:guests?/:rooms?/:preselectedBusinessUnitId?/:breakfastIncluded?",
    props: (route) => ({
      reservationType: "hotel",
      ...route.params
    }),
    name: PAGES.HotelRebook,
    component: HotelReservationForm
  },
  {
    path: "/cityreservation/:city/:checkin/:nights/:adults/:rooms/:latitude?/:longitude?/:breakfastIncluded?",
    props: (route) => ({
      reservationType: "city",
      ...route.params
    }),
    name: PAGES.CityReservationForm,
    component: HotelReservationForm
  },
  {
    path: "/meetingroomreservation",
    props: (route) => ({
      ...route.params
    }),
    name: PAGES.MeetingRoomReservation,
    component: MeetingRoomReservation
  },
  {
    path: "/reservation-overview",
    props: (route) => ({
      ...route.params
    }),
    name: PAGES.ReservationOverview,
    component: ReservationOverview
  }
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  const isLoggedIn = CommonHelper.isLoggedIn();
  const hasToken = CommonHelper.hasToken();

  if (
    to.name !== PAGES.Login &&
    to.name !== PAGES.SSO &&
    !isLoggedIn &&
    !hasToken
  ) {
    //console.log("rd 1 (page requested, not logged in)");
    next({ name: PAGES.Login });
  } else if (to.name == PAGES.Login && !isLoggedIn && hasToken) {
    //console.log("rd 2 (Login request, has token)");
    next({ name: PAGES.MFA });
  } else if (to.name == PAGES.Login && isLoggedIn) {
    //console.log("rd 3 (login requested, is logged in)");
    next({ name: PAGES.LandingPage });
  } else {
    //console.log("rd 4 (pass)");
    // This is for the search page, which is not tracked when the feedbackid is added
    const isSamePageRedirect = from?.name === to?.name;
    let isIngorableSearchPage = to.params?.feedbackId && isSamePageRedirect;

    if (!isIngorableSearchPage) {
      trackEvent({
        event: "pageview",
        pageTitle: to.name,
        pageUrl: to.path,
        locale: localStorage.getItem("locale") || "nl"
      });
    }
    next();
  }
});

const trackEvent = (config) => {
  window.dataLayer.push(config);
};

const app = Vue.createApp(App).use(i18n);

app.use(VueSvgInlinePlugin, {
  attributes: {
    data: ["src"],
    remove: ["alt"]
  }
});
app.mixin(mixins);
app.use(router);
app.use(VueAxios, axios);
axios.defaults.withCredentials = true;
app.component("NotificationMessage", NotificationMessage);

const mainWrapper = document.getElementById("app");
mainWrapper.style.opacity = "0";

const checkSessionValidity = async () => {
  let currentUrl = window.location.href;
  if (currentUrl.includes("login") || currentUrl.includes("sso")) {
    console.log("Skipping validation for", currentUrl);
    mainWrapper.style.opacity = "1";
    return;
  }
  const sessionIsValid = await CommonHelper.sessionIsValid();
  mainWrapper.style.opacity = "1";
  if (!sessionIsValid) {
    await CommonHelper.invalidateSession();
    router.push({ name: PAGES.Login });
  } else {
    checkCompanyInfoDate();
  }
};
const checkCompanyInfoDate = async () => {
  const ciDate = localStorage.ciDate;
  const todaysHash = CommonHelper.getDateHash();
  if (ciDate !== todaysHash) {
    await CommonHelper.fetchCompanyInfo();
  }
};

checkSessionValidity();

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const statusCode = error.response.status;
    console.log("===== ERROR CAUGHT ===== ", error);

    if (statusCode === 401) {
      if (
        !error.request.responseURL.includes("checktwofactor") &&
        !error.request.responseURL.includes("brand.css") &&
        !error.request.responseURL.includes("setcookie") &&
        !error.request.responseURL.includes("logout")
      ) {
        await CommonHelper.invalidateSession(true);
        if (window.location.pathname !== "/") {
          router.push({ name: PAGES.Login });
        }
      }
    }
    if (statusCode === 500) {
      console.error(error.code, error.message);
    }
    return error.response;
  }
);

app.mount("#app");
