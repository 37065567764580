<template>
  <div class="search-loading">
    <img width="50" height="60" src="@/assets/images/loader.svg" />
    <br />

    <span>
      {{
        $t("loadingStatus", {
          percentage: loadingData.progress ? loadingData.progress : 0
        })
      }},
      {{
        $t("loadingCount", {
          count: loadingData.hotelCount ? loadingData.hotelCount : 0
        })
      }}
    </span>
    <progress class="progress" :value="loadingData.progress" max="100">
      {{ loadingData.progress }}
    </progress>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingText: this.$t("hotelsLoading")
    };
  },
  props: {
    loadingData: {
      progress: 0,
      hotelCount: 0
    }
  },
  watch: {
    "loadingData.progress": {
      handler(newValue) {},
      immediate: true
    }
  }
};
</script>
<style scoped lang="scss">
.search-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background: rgb(1 1 1 / 45%);
  color: white;
  justify-content: center;
  display: flex;
  height: 200px;
  padding: 1vh;
  flex-wrap: wrap;
  > span {
    text-align: center;
    flex: 1 1 100%;
  }
  span {
    padding: 15px 0;
  }
}
</style>
