<template>
  <div>
    <label for="businessUnitSearch">{{
      tbu("ReservationForm.findBusinessUnit")
    }}</label>
    <div class="control">
      <input
        class="input"
        name="businessUnitSearch"
        id="businessUnitSearch"
        v-model="businessUnitSearch"
        @keyup="findBusinessUnit"
        type="text"
      />
    </div>
    <div class="content">
      <ul style="max-height: 250px; overflow-y: auto; margin-left: 0">
        <li v-for="found in foundBusinessUnits" :key="found.id">
          <a @click="selectBusinessUnit(found)" class="is-underlined">{{
            found.name
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preselectedBusinessUnitId: {
      type: String,
      default: null
    },
    businessUnits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      businessUnitSearch: "",
      foundBusinessUnits: [],
      businessUnit: null
    };
  },

  mounted() {
    if (this.preselectedBusinessUnitId) {
      const preslected = this.businessUnits.find(
        (bU) => bU.id === this.preselectedBusinessUnitId
      );
      if (preslected !== null) {
        this.selectBusinessUnit(preslected);
      }
    } else {
      this.findBusinessUnit();
    }
  },
  methods: {
    selectBusinessUnit(businessUnit) {
      this.businessUnitSearch = "";
      this.foundBusinessUnits = [];
      this.$emit("selectedBusinessUnit", { businessUnit: businessUnit });
    },
    findBusinessUnit() {
      const matchString = this.businessUnitSearch.toLowerCase();
      this.foundBusinessUnits = this.businessUnits.filter(
        (bU) =>
          bU.name.toLowerCase().includes(matchString) && bU.name !== "<NOUNIT>"
      );
      //.slice(0, 25);
    }
  }
};
</script>

<style lang="scss" scoped></style>
