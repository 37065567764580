<template>
  <div class="paging">
    <span
      class="paging__number"
      :class="{ 'paging__number--selected': item == currentPage }"
      v-for="item in getPages"
      :key="item"
      v-on:click="selectPage(item)"
    >
      {{ item }}
    </span>
  </div>
</template>

<script>
// @ts-check
export default {
  name: "Paging",
  props: {
    pageCount: Number,
    currentPage: Number
  },
  emits: ["page"],
  methods: {
    /**
     * @param {string | Number} page
     */
    selectPage(page) {
      if (Number.isInteger(page) == false) return;
      this.$emit("page", page);
    }
  },
  computed: {
    getPages() {
      let pages = [];
      let maxPages = 10;
      if (this.pageCount < maxPages) {
        if (this.pageCount < maxPages) {
          maxPages = this.pageCount;
        }

        for (let i = 1; i <= maxPages; i++) {
          pages.push(i);
        }
      } else {
        let firstPage = this.currentPage - parseInt(maxPages / 2);
        let lastPage = this.currentPage + parseInt(maxPages / 2 + 1);
        if (firstPage < 1) {
          firstPage = 1;
        }
        if (firstPage > 1) {
          pages.push(1);
          pages.push("...");
        }
        if (lastPage > this.pageCount) {
          lastPage = this.pageCount;
        }
        for (let i = firstPage; i <= lastPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    }
  }
};
</script>
