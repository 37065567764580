<template>
  <div>
    <teleport to="#inputSelectionTarget" v-if="isOpen">
      <div v-if="isOpen" class="datepicker-foldout">
        <Datepicker
          v-model="date"
          range
          :minDate="new Date()"
          @update:modelValue="handleDate"
          :enableTimePicker="false"
          inline
          autoApply
          multiCalendars
          locale="nl"
        />
        <p class="pt-3">
          {{ $t("selectCheckinAndCheckOutDate") }}
        </p>
        <span @click="isOpen = false" class="c-close-foldout">{{
          $t("close")
        }}</span>
      </div>
    </teleport>
    <div class="searchbar__icon">
      <i class="fas fa-calendar-day"></i>
    </div>
    <input
      placeholder="Datum"
      type="text"
      class="searchbar__input"
      name="dates"
      v-model="datePickerText"
      autocomplete="off"
      v-on:click="openFoldout()"
    />
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
//import "@vuepic/vue-datepicker/dist/main.css";

//https://vue3datepicker.com/api/props/#format
import { nl } from "date-fns/locale";
import moment from "moment";

export default {
  name: "DatepickerFoldout",
  created: function () {
    this.locale = nl;
  },
  components: {
    Datepicker
  },
  emits: ["selectDates"],
  data() {
    return {
      date: null,
      today: new Date(),
      departureDate: new Date(),
      minDepartureDate: new Date(),
      isOpen: false,
      datePickerText: null
    };
  },
  mounted() {
    this.departureDate = moment(this.arrivalDate).add("1", "days").toDate();
    this.setDatePickerText();
    this.emitDates();
  },
  methods: {
    openFoldout() {
      this.isOpen = true;
    },
    closeFoldout() {
      this.isOpen = false;
    },
    selectArrival(arrivalDate) {
      let arrival = new Date(arrivalDate);
      this.arrivalDate = arrivalDate;
      this.minDepartureDate = moment(arrival).add("1", "days").toDate();

      if (moment(this.arrivalDate).isAfter(moment(this.departureDate))) {
        this.departureDate = moment(this.arrivalDate).add("1", "days").toDate();
      }
      this.setDatePickerText();
    },
    selectDeparture(departureDate) {
      let departure = new Date(departureDate);
      this.departureDate = departure;

      this.isOpen = false;
      this.setDatePickerText();
      this.emitDates();
    },
    handleDate(date) {
      const [startDate, endDate] = date;

      this.arrivalDate = startDate;
      this.departureDate = endDate;

      this.isOpen = false;
      this.setDatePickerText();
      this.emitDates();
    },
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `Selected date is ${day}/${month}/${year}`;
    },
    emitDates() {
      let nights = moment(this.departureDate).diff(
        moment(this.arrivalDate),
        "days"
      );
      if (nights == 0) nights = 1;

      let returnObj = {
        Checkin: moment(this.arrivalDate).format("YYYY-MM-DD"),
        Nights: nights
      };

      this.$emit("selectDates", returnObj);
    },
    setDatePickerText() {
      let formattedArrival = moment(this.arrivalDate).format("DD-MM-YYYY");
      let formattedDeparture = moment(this.departureDate).format("DD-MM-YYYY");

      this.datePickerText = formattedArrival + "  -  " + formattedDeparture;
    }
  }
};
</script>
