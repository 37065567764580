<template>
  <div class="results-list__item__row">
    <div v-if="this.score >= 9" class="results-list__item__score">
      <span class="pill--dark-green">{{ score }}</span>
      <strong>{{ $t("reviews.reallyGood") }}</strong>
      ({{ $t("reviewCount", getReviewCount()) }})
    </div>

    <div v-else-if="this.score > 7" class="results-list__item__score">
      <span class="pill--green">{{ score }}</span>
      <strong>{{ $t("reviews.good") }}</strong> ({{
        $t("reviewCount", getReviewCount())
      }})
    </div>

    <div v-else-if="this.score >= 6" class="results-list__item__score">
      <span class="pill--orange">{{ score }}</span>
      <strong>{{ $t("reviews.average") }}</strong> ({{
        $t("reviewCount", getReviewCount())
      }})
    </div>

    <div v-else-if="this.score >= 1" class="results-list__item__score">
      <span class="pill--red">{{ score }}</span>
      <strong>{{ $t("reviews.bad") }}</strong> ({{
        $t("reviewCount", getReviewCount())
      }})
    </div>

    <div v-else class="results-list__item__score">
      <span class="pill--grey">{{ score }}</span>
      <strong>{{ $t("noReviews") }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reviews",
  props: {
    score: Number,
    amount: Number
  },
  methods: {
    getReviewCount() {
      const reviewCount = this.formatNumber(this.amount);
      return { count: this.amount, numberOfReviews: reviewCount };
    }
  }
};
</script>
