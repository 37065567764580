<template>
  <div class="details reservationoverview">
    <div class="details__title px-5">
      <h1>{{ $t("reservationOverview") }}</h1>
    </div>
    <div class="container mt-6">
      <div class="columns is-multiline is-mobile">
        <div class="column is-full">
          <div
            class="details__content__box columns is-align-items-flex-end is-align-items-bottom"
          >
            <div class="column">
              <label for="startdateStart">{{
                $t("dateTimeSelection.startDateFrom")
              }}</label>
              <input
                id="startdateStart"
                type="date"
                :placeholder="$t('reservationoverview.startdate_start')"
                v-model="fromDate"
              />
            </div>
            <div class="column">
              <label class="" for="startdateEnd">
                {{ $t("dateTimeSelection.startDateTo") }}</label
              >
              <input
                id="startdateEnd"
                type="date"
                :placeholder="$t('reservationoverview.startdate_end')"
                v-model="toDate"
              />
            </div>

            <div class="column is-one-fifth">
              <button
                @click="findReservations"
                :disabled="buttonDisabled"
                class="button"
              >
                {{ $t("apply") }}
              </button>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div
            class="details__content__box columns is-align-items-flex-end is-align-items-bottom"
          >
            <div class="column">
              <input
                id="cityInput"
                type="text"
                :placeholder="$t('reservationoverview.omniSearch')"
                v-model="omniFilter"
              />
            </div>

            <div class="column">
              <b>{{ $t("show") }}:</b><br />
              <label class="pr-3">
                <input type="radio" v-model="visibleItems" value="FUTURE" />
                {{ $t("future") }}
              </label>
              <label class="pr-3">
                <input type="radio" v-model="visibleItems" value="CURRENT" />
                {{ $t("current") }}
              </label>
              <label>
                <input type="radio" v-model="visibleItems" value="ALL" />
                {{ $t("all") }}
              </label>
            </div>

            <div class="column">
              <div class="buttons has-addons is-right">
                <template
                  v-for="filterable in filterables"
                  v-bind:key="filterable.id"
                >
                  <button
                    @click="toggleFilterable(filterable)"
                    class="button"
                    :class="{ 'is-primary is-selected': filterable.isSelected }"
                  >
                    <i
                      v-if="filterable.isSelected"
                      class="fa-regular fa-square-check pr-3"
                    ></i>
                    <i v-else class="fa-regular fa-square pr-3"></i>
                    {{ filterable.title }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="details__content__box columns has-text-centered">
            <div class="column">
              <p v-html="$t('reservationOverviewMessage')"></p>
            </div>
          </div>
        </div>
        <div class="column is-full" v-if="reservations.length == 500">
          <div class="details__content__box columns has-text-centered">
            <div class="column content">
              <p style="color: orange">
                <i
                  style="color: orange"
                  class="fa-solid fa-triangle-exclamation"
                ></i>
                {{ $t("reservationOverviewLimitedResults") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="details__content container loading-container mt-5"
          v-if="isLoading"
        >
          <div class="details__content__box has-text-centered">
            <h3 class="title is-3">{{ $t("reservationoverview.loading") }}</h3>
            <br />
            <img width="50" height="60" src="@/assets/images/loader.svg" />
          </div>
        </div>

        <template v-for="r in visibleReservations" v-bind:key="r.id">
          <ReservationItem
            :reservation="r"
            @rebook="rebook"
            @cancelledItems="cancelledItems"
            :visibleItems="visibleItems"
          />
        </template>
      </div>
    </div>
    <NotificationMessage
      :level="notification.level"
      :autohide="false"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
  </div>
</template>

<script>
import ReservationItem from "./components/reservationoverview/ReservationItem.vue";
import ReservationHelper from "@/services/reservation.helper";
import moment from "moment";

export default {
  data() {
    return {
      filterables: [],
      reservations: [],
      fromDate: new moment().subtract(1, "weeks").format("yyyy-MM-DD"),
      toDate: new moment().add(4, "weeks").format("YYYY-MM-DD"),
      cityInput: "",
      hotelName: "",
      omniFilter: "",
      buttonDisabled: false,
      visibleItems: "FUTURE",
      isLoading: true,
      pagination: {
        currentPage: 1,
        pageSize: 2
      }
    };
  },
  components: {
    ReservationItem
  },
  async created() {
    this.findReservations();
    this.addFilterable("cancelled", this.$t("hideCancelled"), true);
  },

  methods: {
    cancelledItems() {
      this.notification.message = this.$t("confirmCancellationRequested");
      this.notification.level = "info";
      this.notification.show = true;

      setTimeout(() => {
        this.notification.show = false;
      }, 10 * 1000);
      this.findReservations();
    },
    async findReservations() {
      if (this.fromDate && this.toDate) {
        this.isLoading = true;
        this.reservations = [];
        this.buttonDisabled = true;

        let companyInfo = JSON.parse(localStorage.companyInfo);

        let companyId = companyInfo?.company?.id ?? "unknown";
        let reservations = await ReservationHelper.getReservations(
          this.fromDate,
          this.toDate
        );
        this.buttonDisabled = false;
        if (reservations) {
          if (reservations.length == 500) {
            let message = `dateRangeTooBig 500 returned: Selection ${this.fromDate} - ${this.toDate}`;
            this.track("generalError", {
              page: "reservationOverview",
              message,
              companyId
            });
          }

          this.reservations = reservations;
        } else {
          this.notification.title = this.$t("genericErrorTitle");
          this.notification.message = this.$t("dateRangeTooBig");
          let message = `dateRangeTooBig: Selection ${this.fromDate} - ${this.toDate}`;
          this.track("generalError", {
            page: "reservationOverview",
            message,
            companyId
          });

          this.notification.level = "danger";
          this.notification.show = true;
          setTimeout(() => {
            this.notification.show = false;
          }, 10 * 1000);
        }
        this.isLoading = false;
      } else {
        this.notification.title = this.$t("genericErrorTitle");
        this.notification.message = this.$t("enterDates");
        this.notification.level = "warning";
        this.notification.show = true;
        setTimeout(() => {
          this.notification.show = false;
        }, 10 * 1000);
      }
    },

    rebook(data) {
      const { reservation, dateRange, adults, employees, rebookOptions } = data;

      let params = {
        hotelId: reservation.hotel.id
      };
      params.rooms = employees.split(",").length ?? 1;
      params.guests = "";
      params.adults = 1;
      params.preselectedBusinessUnitId = "";
      if (rebookOptions.sameGuest) {
        params.guests = employees;
        params.adults = adults;
      }
      if (rebookOptions.sameBusinessUnit) {
        params.preselectedBusinessUnitId = reservation.businessUnit.id;
      }
      params.locationPath = "direct";
      if (rebookOptions.sameDate) {
        params.checkin = dateRange.startDate.format("YYYY-MM-DD");
        params.nights = dateRange.endDate.diff(dateRange.startDate, "days") + 1;
        params.locationPath = "hasChosenDate";
      }

      this.$router.push({
        name: this.PAGES.HotelRebook,
        params
      });
    },
    addBooking(hotelName, startDate, endDate, guests = []) {},
    addFilterable(id, title, isSelected = false) {
      this.filterables.push({ id, title, isSelected });
    },
    toggleFilterable(filterable) {
      filterable.isSelected = !filterable.isSelected;
    }
  },
  computed: {
    visibleReservations() {
      const omniFilter = this.omniFilter.toLowerCase();
      const hideCancelled = this.filterables[0].isSelected;

      return this.reservations.filter((reservation) => {
        const hotelName = reservation.hotel?.name?.toLowerCase();
        const hotelCity = reservation.hotel?.city?.toLowerCase();
        const employees = reservation.guestsWithDates
          .map((gwd) => {
            return (
              gwd.employee.firstName +
              " " +
              gwd.employee.lastName
            ).toLowerCase();
          })
          .join(" ");

        let matchesOmni =
          omniFilter === ""
            ? true
            : hotelName.includes(omniFilter) ||
              hotelCity.includes(omniFilter) ||
              employees.includes(omniFilter);
        let matchesFilterables = true;
        if (hideCancelled && reservation.allDatesCancelled) {
          matchesFilterables = false;
        }

        return matchesFilterables && matchesOmni;
      });
    }
  }
};
</script>
<style scoped>
.details__title {
  background: url("@/assets/images/meetingroom.jpeg") center center no-repeat;
  background-size: cover;
}
</style>
