<template>
  <div class="home">
    <div class="wrapper--home">
      <div class="main-searchbar--outlet" id="inputSelectionTarget"></div>
      <div class="main-searchbar">
        <div class="main-searchbar--input">
          <h2 class="main-searchbar__title">
            {{ $t("searchPage.header") }}
          </h2>

          <form @submit.prevent="submitSearch" class="main-searchbar__form">
            <div
              class="main-searchbar__location-input searchbar__input-wrapper"
            >
              <LocationAutoComplete
                name="location"
                @selectLocation="selectLocation"
                @hasLocationResult="hasLocationResult"
              />
            </div>
            <div
              class="main-searchbar__checkin-input searchbar__input-wrapper"
              :class="{ 'landing-page-element--hidden': hideNonLocation }"
            >
              <DatepickerFoldout @selectDates="selectDates" />
            </div>
            <div
              class="main-searchbar__meta-input searchbar__input-wrapper"
              :class="{ 'landing-page-element--hidden': hideNonLocation }"
            >
              <div class="columns is-mobile">
                <div class="column is-half-mobile">
                  <div class="input-icon">
                    <div class="input-icon--wrapper">
                      <i
                        class="fas fa-person"
                        :title="$t('searchFilters.numberOfPersons')"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <input
                      id="adults"
                      type="number"
                      class="searchbar__input"
                      min="1"
                      max="8"
                      :placeholder="$t('searchFilters.numberOfPersons')"
                      v-model="adults"
                    />
                  </div>
                </div>
                <div class="column is-half-mobile">
                  <div class="input-icon">
                    <div class="input-icon--wrapper">
                      <i
                        class="fas fa-tent"
                        :title="$t('searchFilters.numberOfRooms')"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <input
                      id="rooms"
                      type="number"
                      class="searchbar__input"
                      min="1"
                      :max="adults"
                      :placeholder="$t('searchFilters.numberOfRooms')"
                      v-model="rooms"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="main-searchbar__submit"
              :class="{ 'landing-page-element--hidden': hideNonLocation }"
            >
              <button type="submit" class="c-button" :disabled="location == ''">
                {{ $t("search") }}
              </button>
              <template v-if="location && 2 == 3">
                <br />
                <br />
                <a @click="gotoCityReservation()">
                  {{ $t("doYouWishToMakeACityReservation") }}
                </a>
              </template>
            </div>
          </form>
        </div>
      </div>

      <div
        class="main-favorites"
        :class="{ 'landing-page-element--hidden': hideNonLocation }"
      >
        <FavoriteHotels></FavoriteHotels>
      </div>
    </div>
  </div>
</template>

<script>
import LocationAutoComplete from "./components/landingpage/LocationAutoComplete.vue";
import DatepickerFoldout from "./components/landingpage/DatepickerFoldout.vue";
import FavoriteHotels from "./components/landingpage/FavoriteHotels.vue";
import GoogleLocationSearch from "./components/shared/GoogleLocationSearch";
export default {
  name: "Home",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GoogleLocationSearch,
    FavoriteHotels,
    LocationAutoComplete,
    DatepickerFoldout
  },
  data() {
    return {
      location: "",
      adults: 1,
      rooms: 1,
      selectedLocationObj: {},
      dates: "",
      hideNonLocation: false
    };
  },
  methods: {
    selectLocation(location) {
      this.selectedLocationObj = location;
      this.location = location.locationPath;
    },
    gotoCityReservation() {
      this.$router.push({
        name: this.PAGES.CityReservationForm,
        params: {
          city: this.location,
          checkin: this.dates.Checkin,
          nights: this.dates.Nights,
          rooms: 1,
          adults: this.adults
        }
      });
    },
    hasLocationResult(hasLocationResult) {
      this.hideNonLocation = hasLocationResult;
    },
    selectDates(dates) {
      this.dates = dates;
    },
    submitSearch() {
      if (this.location != "") {
        if (this.selectedLocationObj.featureCode === "HTL") {
          this.track("directHotelHome", {
            hotelCity: this.selectedLocationObj.admin1,
            hotelName: this.selectedLocationObj.name,
            hotelId: this.selectedLocationObj.id,
            checkin: this.dates.Checkin,
            nights: this.dates.Nights,
            adults: this.adults,
            rooms: this.rooms
          });
          this.$router.push({
            name: this.PAGES.HotelDetail,
            params: {
              locationPath: "direct",
              hotelId: this.selectedLocationObj.hotelId,
              checkin: this.dates.Checkin,
              nights: this.dates.Nights,
              refundable: true,
              adults: this.adults,
              rooms: this.rooms
            }
          });
        } else {
          this.track("hotelSearchHome", {
            city: this.selectedLocationObj.name,
            country: this.selectedLocationObj.country,
            checkin: this.dates.Checkin,
            nights: this.dates.Nights,
            adults: this.adults,
            rooms: this.rooms
          });
          this.$router.push({
            name: this.PAGES.SearchResult,
            params: {
              locationPath: this.location,
              checkin: this.dates.Checkin,
              nights: this.dates.Nights,
              refundable: true,
              adults: this.adults,
              rooms: this.rooms
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  margin: 0;
  background: center no-repeat;
  background-image: var(--brand-background-image);
  background-size: cover;
}

.logo--big {
  position: absolute;
  right: 0;
  height: 60px;
  top: 50%;
  margin-top: -30px;
}
.main-searchbar--input {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main-searchbar__title {
  flex-grow: 0;
}
.main-searchbar__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  place-content: flex-end space-around;
}
</style>
