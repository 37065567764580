<template>
  <div class="details__price details__content__box">
    <template v-if="isDirect && 2 == 3">
      <div class="notification is-warning is-size-7 has-text-centered">
        <p>
          <i style="color: orange" class="fa-solid fa-triangle-exclamation"></i>
          {{ $t("directBookingNotice") }}
          <i style="color: orange" class="fa-solid fa-triangle-exclamation"></i>
        </p>
      </div>
    </template>
    <template v-if="lowestOffer">
      <OfferSummary
        v-if="lowestOffers.refundable.breakfastIncluded"
        :nights="nights"
        :offer="lowestOffers.refundable.breakfastIncluded"
      >
      </OfferSummary>

      <OfferSummary
        v-if="lowestOffers.refundable.breakfastNotIncluded"
        :nights="nights"
        :offer="lowestOffers.refundable.breakfastNotIncluded"
      >
      </OfferSummary>

      <OfferSummary
        v-if="lowestOffers.nonRefundable.breakfastIncluded"
        :nights="nights"
        :offer="lowestOffers.nonRefundable.breakfastIncluded"
      >
      </OfferSummary>

      <OfferSummary
        v-if="lowestOffers.nonRefundable.breakfastNotIncluded"
        :nights="nights"
        :offer="lowestOffers.nonRefundable.breakfastNotIncluded"
      >
      </OfferSummary>

      <p
        v-if="hasExceedingBudget"
        class="has-text-black mb-2 is-size-7 has-background-warning p-3"
      >
        {{ $t("warningOverBudget", { budget }) }}
      </p>

      <div class="main-searchbar__submit">
        <button type="button" class="c-button">
          {{ $t("requestReservation") }}
        </button>
      </div>

      <div class="details__price--basis">
        <span>
          {{
            $t("basedOn", {
              count: parseInt(nights),
              adults: parseInt(adults),
              rooms: parseInt(adults),
              startDate: startDate,
              endDate: endDate
            })
          }}<br />
          {{ $t("basedOnChange") }}
        </span>
      </div>
    </template>
    <template v-else>
      <p class="is-size-7">
        {{ $t("noPriceFoundRequestAnyway") }}
      </p>
      <!--
        <br />&nbsp;<br />
        <br />
        {{
          $t("basedOn", {
            count: parseInt(nights),
            adults: parseInt(adults),
            rooms: parseInt(adults),
            startDate: startDate,
            endDate: endDate,
          })
        }}
        <br />
        {{ $t("basedOnChange") }}
      <div class="main-searchbar__submit">
        <button type="button" class="c-button">
          {{ treq("requestReservation") }}
        </button>
      </div>
      -->
    </template>
  </div>
</template>
<script>
import HotelHelper from "@/services/hotel.helper.js";
import CommonHelper from "@/services/common.helper.js";
import OfferSummary from "./OfferSummary.vue";
export default {
  name: "DetailPrice",
  components: {
    OfferSummary
  },
  methods: {
    getPrice(offer) {
      return HotelHelper.getPrice(offer);
    },

    getPricePerNight(offer) {
      return HotelHelper.getPricePerNight(offer, this.nights);
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      lowestOffer: null,
      budget: null,
      lowersOffers: null,
      hasExceedingBudget: false,
      suppliersWithOffers: []
    };
  },
  mounted() {
    let dateRange = CommonHelper.getDateRange(this.checkin, this.nights);
    this.startDate = dateRange.startDateString;
    this.endDate = dateRange.endDateString;
  },
  async beforeCreate() {
    const companyInfo = await CommonHelper.getCompanyInfo();
    this.budget = companyInfo.budget;
  },
  watch: {
    hotel: {
      async handler(newValue) {
        if (newValue.suppliers) {
          let priceSummary = HotelHelper.getPriceSummary(newValue.suppliers);
          this.lowestOffer = priceSummary.lowestOffer;
          this.lowestOffers = priceSummary.lowestOffers;
          const companyInfo = await CommonHelper.getCompanyInfo();
          this.budget = companyInfo.budget;

          if (
            this.budget &&
            this.lowestOffer &&
            this.lowestOffer.price / this.nights > this.budget
          ) {
            this.hasExceedingBudget = true;
          } else {
            this.hasExceedingBudget = false;
          }

          this.suppliersWithOffers = priceSummary.suppliersWithOffers;
        }
      },
      immediate: true
    }
  },
  props: {
    hotel: {},
    checkin: String,
    nights: String,
    adults: String,
    isDirect: Boolean,
    rooms: String
  }
};
</script>
