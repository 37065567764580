<template>
  <div class="main-login">
    <div class="login-wrapper p-5">
      <div v-if="isFetchingCompanyData" class="isLoading">
        <h3 class="title is-3 has-text-centered p-3">
          {{ $t("MfaPage.accountDataFetching") }}
          <br />
          <br />
          <img width="50" height="60" src="@/assets/images/loader.svg" />
        </h3>
      </div>
      <form @submit.prevent="CheckMFA()" v-else>
        <div class="columns is-multiline">
          <div class="field column is-full">
            <h1 class="is-h1 title">{{ $t("MfaPage.header") }}</h1>
            <p>{{ $t("MfaPage.intro") }}</p>
          </div>

          <div class="field column is-full">
            <label class="twofactorcode">{{
              $t("MfaPage.twoFactorCode")
            }}</label>
            <div class="control">
              <input
                class="input"
                name="twofactorcode"
                v-model="loginData.twofactorcode"
                required
                autocomplete="one-time-code"
                type="text"
                pattern="[0-9]*"
                minlength="6"
                maxlength="6"
                :placeholder="$t('MfaPage.twoFactorCode')"
              />
            </div>
          </div>
          <div class="field column is-half">
            <div class="control has-text-left">
              <a class="mt-3" @click="redirectLogin()">
                {{ $t("MfaPage.toLoginForm") }}
              </a>
            </div>
          </div>
          <div class="field column is-half">
            <div class="control has-text-right">
              <img
                width="30"
                height="30"
                v-if="isLoading"
                src="@/assets/images/loader.svg"
                class="c-loading-icon"
              />

              <button type="submit" class="c-button is-primary is-medium">
                {{ $t("MfaPage.mfaLogin") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <notification-message
      :level="'warning'"
      :title="notificationTitle"
      :message="notificationMessage"
      :show="showNotification"
      @toggle="toggleMFAError"
    />
  </div>
</template>

<script>
import NotificationMessage from "./components/shared/NotificationMessage.vue";
import CommonHelper from "@/services/common.helper.js";

export default {
  components: {
    NotificationMessage
  },
  name: "Login",
  data() {
    return {
      showNotification: false,
      notificationTitle: "",
      notificationMessage: "",
      isLoading: false,
      isFetchingCompanyData: false,
      loginData: {
        twofactorcode: null
      }
    };
  },
  async mounted() {},
  methods: {
    redirectLogin() {
      this.$router.go({ name: this.PAGES.Login });
    },
    async CheckMFA() {
      const url = `${this.AVAILABILITY_API_URL}/authentication/checktwofactor`;
      this.isLoading = true;
      const token = CommonHelper.getToken();

      let { data, status } = await this.axios.post(url, {
        token: token,
        twofactorcode: this.loginData.twofactorcode
      });

      if (status == 200) {
        CommonHelper.saveSession(data.username);
        try {
          this.isFetchingCompanyData = true;
          await this.getCompanyInfo();
          this.sleep(500);
          await this.injectCustomCss();
          this.isFetchingCompanyData = false;
          this.isLoading = false;
          this.$router.push({
            name: this.PAGES.LandingPage
          });

          // Error logging in
        } catch (e) {
          this.isLoading = false;
          this.isFetchingCompanyData = false;
          this.notificationMessage = this.$t("errorLoggingIn");
          this.showNotification = true;
        }
      } else if (status == 401) {
        // 2FA Check error
        this.isLoading = false;
        this.notificationMessage = this.$t("MfaPage.errorInvalidTwofactorCode");
        this.loginData.twofactorcode = "";
        this.showNotification = true;

        this.track("MFAFailure", {
          error: "Wrong code"
        });
        // 2FA invalid
      } else if (status == 500) {
        this.isLoading = false;
        this.notificationMessage = this.$t("MfaPage.errorTwoFactorCheck");
        this.showNotification = true;
        this.track("MFAFailure", {
          error: "Rate limited"
        });
        this.sleep(1000);
        this.$router.go({ name: this.PAGES.Login });
      }

      this.isLoading = false;
    },

    toggleMFAError(e) {
      this.showNotification = e;
    }
  }
};
</script>

<style lang="scss">
.main-login {
  margin: 0;
  height: 100vh;
  background: center no-repeat;
  background-image: var(--brand-background-image);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper {
  background: white;
  margin: 0 12px;
  display: flex;
  box-shadow: -10px -2px 10px 3px #36363661;
  align-self: center;
  max-width: 600px;
  border-radius: 8px;
}

.logo--big {
  position: absolute;
  right: 0;
  height: 60px;
  top: 50%;
  margin-top: -30px;
}
.control .c-loading-icon {
  position: relative;
  top: 8px;
  right: 16px;
}
</style>
