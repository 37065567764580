<template>
  <div>
    <h5 class="title is-5">{{ title }}</h5>
    <template v-for="(value, key) in amenitiesList" v-bind:key="key">
      <div v-if="value !== null" class="mb-5">
        <h6 class="title is-6">{{ $t("expedia." + key) }}</h6>
        <br />
        {{ cleanValue(value) }}
      </div>
    </template>
    <template v-if="flatAmenitiesList?.length > 0">
      <ul class="mt-0" v-html="`<li>${cleanedFlatAmenities}</li>`"></ul>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  name: "AmenitiesList",
  props: {
    amenitiesList: Object,
    flatAmenitiesList: Array,
    title: String
  },
  mounted() {},
  data: function () {
    return {};
  },
  methods: {
    cleanValue(value) {
      return value.map((v) => v.trim()).join(", ");
    }
  },
  computed: {
    cleanedAmenities() {
      return this.data;
    },
    cleanedFlatAmenities() {
      return this.flatAmenitiesList
        .map((a) => {
          return this.$t("expedia." + a);
        })
        .join("</li><li>");
    }
  }
};
</script>

<style lang="sass" scoped></style>
