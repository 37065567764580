<template>
  <div>
    <div class="columns is-multiline details__content__box mb-5">
      <div class="column is-half">
        <label for="roomLayout">{{
          $t("meetingReservation.roomLayout")
        }}</label>
        <br />
        <div class="select">
          <select
            v-model="roomLayout"
            @change="updateValue()"
            :required="false"
            id="roomLayout"
          >
            <option></option>
            <option
              v-for="option in selectables.roomLayouts"
              :key="option"
              :value="option"
            >
              {{ $t("roomLayouts." + option) }}
            </option>
          </select>
        </div>
      </div>
      <div class="column is-half">
        <label for="singleRoom">
          {{ $t("searchFilters.numberOfPersons") }}
        </label>
        <input type="text" @change="updateValue()" v-model="personCount" />
      </div>
      <div class="column is-half">
        <label for="catering">{{ $t("meetingReservation.catering") }}</label>

        <label
          class="checkbox"
          v-for="option in selectables.selectableCatering"
          :key="option"
          @change="updateValue()"
        >
          <input
            type="checkbox"
            name="inputValue"
            :required="true"
            v-model="catering"
            :value="option"
          />

          {{ $t("cateringOption." + option) }}
        </label>
      </div>
      <div class="column is-half">
        <label for="facilities">{{ $t("facilitiesLabel") }}</label>
        <div class="control">
          <label
            class="checkbox"
            v-for="option in selectables.selectableFacilities"
            :key="option"
            @change="updateValue()"
          >
            <input
              type="checkbox"
              name="inputValue"
              :required="true"
              v-model="facilities"
              :value="option"
            />
            {{ $t("facilityOption." + option) }}
          </label>
        </div>
      </div>

      <div class="column is-full">
        <b>{{ $t("meetingReservation.needHotelRoom") }}</b>
        <label class="checkbox">
          <input
            type="checkbox"
            name="needHotelRoom"
            :required="true"
            v-model="needHotelRoom"
            @change="updateValue()"
            :value="true"
          />
          {{ $t("meetingReservation.iNeedHotelRooms") }}
        </label>
      </div>

      <template v-if="needHotelRoom">
        <div class="column is-half">
          <label for="singleRoom">{{
            $t("meetingReservation.numberSingleRooms")
          }}</label>
          <input type="text" @change="updateValue()" v-model="singleBedrooms" />
        </div>
        <div class="column is-half">
          <label for="singleRoom">{{
            $t("meetingReservation.numberDuoRooms")
          }}</label>
          <input type="text" @change="updateValue()" v-model="dualBedrooms" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CommonHelper from "@/services/common.helper";

export default {
  created() {
    this.selectables = CommonHelper.MeetingRoomSelectables;
  },
  methods: {
    updateValue() {
      this.$emit("updated-value", {
        facilities: this.facilities,
        catering: this.catering,
        personCount: this.personCount,
        needHotelRoom: this.needHotelRoom,
        roomLayout: this.roomLayout,
        singleBedrooms: this.singleBedrooms,
        dualBedrooms: this.dualBedrooms
      });
    }
  },
  data() {
    return {
      selectables: {},
      facilities: [],
      catering: [],
      personCount: null,
      roomLayout: null,
      needHotelRoom: false,
      singleBedrooms: 0,
      dualBedrooms: 0
    };
  }
};
</script>

<style scoped>
.radio,
.checkbox {
  width: 100%;
}
</style>
