<template>
  <div class="mt-5" v-if="customField.type !== 'timepicker'">
    <label style="font-weight: bold">
      {{ getTitle() }}
      <span v-if="customField.mandatory">*</span>
    </label>
    <br />
    <template v-if="customField.type === 'textinput'">
      <input
        type="text"
        @change="updateValue()"
        :required="customField.mandatory"
        v-model="inputValue"
      />
    </template>
    <template v-else-if="customField.type === 'radiobutton'">
      <div class="control">
        <label
          class="radio"
          v-for="option in customField.options"
          :key="option.title"
          @change="updateValue()"
        >
          <input
            type="radio"
            name="inputValue"
            :required="customField.mandatory"
            v-model="inputValue"
            :value="option.value"
          />
          {{ getOptionTitle(option) }}
        </label>
      </div>
    </template>
    <template v-else-if="customField.type === 'checkbox'">
      <label
        class="checkbox"
        v-for="option in customField.options"
        :key="option.title"
      >
        <input
          type="checkbox"
          :required="customField.mandatory"
          v-model="checkboxInputs[option.value]"
          @change="updateValue()"
          :value="option.value"
        />
        {{ getOptionTitle(option) }}
      </label>
    </template>
    <template v-else-if="customField.type === 'dropdown'">
      <div class="select">
        <select
          v-model="inputValue"
          @change="updateValue()"
          :required="customField.mandatory"
        >
          <option></option>
          <option
            v-for="option in customField.options"
            :key="option.title"
            :value="option.value"
          >
            {{ getOptionTitle(option) }}
          </option>
        </select>
      </div>
    </template>
    <template v-else>
      <span>Als u dit ziet; neem dan contact met ons op (Code: 78u3y7u)</span>
      <p>
        {{ customField }}
      </p>
    </template>
    <p v-if="getDescription()">
      <small>{{ getDescription() }}</small>
    </p>
  </div>
</template>

<script>
export default {
  emits: ["settedValue"],
  props: {
    customField: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: "",
      checkboxInputs: []
    };
  },
  methods: {
    updateValue() {
      let saveObject = {
        field: this.customField.name
      };
      if (this.customField.type === "checkbox") {
        saveObject.value = [];

        Object.keys(this.checkboxInputs).forEach((key) => {
          if (this.checkboxInputs[key]) {
            saveObject.value.push(key);
          }
        });
      } else {
        saveObject.value = this.inputValue;
      }

      this.$emit("settedValue", saveObject);
    },
    getOptionTitle(option) {
      let translation = option.translations.find(
        (cF) => cF.languageIso == this.languageIso
      );

      return translation.title;
    },
    getTranslation() {
      let translation = this.customField.translations.find(
        (cF) => cF.languageIso.toLowerCase() == this.languageIso.toLowerCase()
      );
      return translation;
    },
    getTitle() {
      return this.getTranslation().title;
    },
    getDescription() {
      let description = this.getTranslation().description;
      if (description !== this.customField.name) {
        return description;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.radio,
.checkbox {
  width: 100%;
  margin-left: 0;
}
.title {
  width: 100%;
}
</style>
