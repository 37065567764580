<template>
  <div>
    <h5 class="title is-5">
      {{ $t("findLocation") }}
    </h5>

    <input
      type="text"
      v-bind:name="name"
      v-model="searchInput"
      v-on:keyup="getResults"
      :placeholder="$t('location')"
      :class="{ 'searchbar__input--loading': isLoading }"
      autocomplete="off"
      style="max-width: 500px"
    />
    <div class="autocomplete-results" style="max-width: 500px">
      <ul class="autocomplete-results__result-list">
        <li
          class="autocomplete-results__result-list__item"
          v-if="result && result.length == 0"
        >
          {{ $t("nothingFound") }}
        </li>

        <li
          class="autocomplete-results__result-list__item"
          v-for="item in result"
          :key="item"
          v-on:click="selectResult(item)"
        >
          <span class="autocomplete-results__result-list__icon">
            <i v-if="item.featureCode === 'HTL'" class="fas fa-hotel"></i>
            <i v-else class="fas fa-city"></i>
          </span>
          <span class="autocomplete-results__result-list__name"
            >{{ item.name }}, {{ item.admin1 }}, {{ item.country }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CommonHelper from "@/services/common.helper";

export default {
  props: {
    selectableLocationTypes: {
      type: Array,
      default: () => ["all"]
    }
  },
  created() {
    this.allLocationTypes = CommonHelper.GEO_LOCATION_TYPES;
  },
  data() {
    return {
      allLocationTypes: null,
      selectedLocationTypes: [],
      callId: null,
      isLoading: false,
      result: null,
      name: "",
      searchInput: ""
    };
  },
  beforeCreate() {},
  methods: {
    async getResults() {
      if (this.searchInput && this.searchInput.length >= 1) {
        if (this.callId) {
          clearTimeout(this.callId);
        }
        this.callId = setTimeout(async () => {
          this.isLoading = true;

          const data = await CommonHelper.getLocationsFromSearch(
            this.searchInput
          );
          this.result = data.slice(0, 7);
          this.isLoading = false;
        }, 750);
      } else {
        this.result = [];
      }
    },
    selectResult(locationItem) {
      this.searchInput = locationItem.name;
      this.result = null;
      this.$emit("selectLocation", locationItem);
    },
    toggleLocationType(type) {
      if (this.selectedLocationTypes.includes(type)) {
        this.selectedLocationTypes = this.selectedLocationTypes.filter(
          (slt) => slt !== type
        );
      } else {
        this.selectedLocationTypes.push(type);
      }
    },
    locationTypeIsSelected(type) {
      return (
        this.selectedLocationTypes.includes(type) ||
        this.selectedLocationTypes.includes(this.allLocationTypes.ALL)
      );
    }
  }
};
</script>

<style lang="scss">
.autocomplete-results__result-list {
  max-height: 400px;
  max-width: 100%;
}
.autocomplete-results__result-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
</style>
