<template>
  <div>
    <input
      type="text"
      autocomplete="off"
      v-model="searchTerm"
      :placeholder="$t('findAirport')"
    />

    <div
      class="columns"
      v-for="airport in filteredAirports"
      :key="airport.iata"
    >
      <div class="column">
        <span @click="selectedAirport(airport)" class="found-airport">
          {{ airport.name }} ({{ airport.iatacode }}) <br />
          {{ airport.city }} {{ airport.country }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import airportsNL from "@/assets/airports.nl.json";
import airportsEN from "@/assets/airports.en.json";

export default {
  data() {
    return {
      searchTerm: "",
      airports: []
    };
  },
  mounted() {
    this.airports = this.$i18n.locale === "nl" ? airportsNL : airportsEN;
  },
  methods: {
    selectedAirport(airport) {
      this.$emit("airport-selected", airport);
      this.searchTerm = "";
    }
  },
  computed: {
    filteredAirports() {
      if (this.searchTerm === "") {
        return [];
      }

      let foundAirports = this.airports
        .filter((airport) => airport.city != "")
        .filter((airport) => airport.name != "Alle luchthavens")
        .filter(
          (airport) =>
            airport.city
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            airport.name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            airport.iatacode
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
        );

      foundAirports.sort((a, b) => {
        if (
          a.city.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          !b.city.toLowerCase().includes(this.searchTerm.toLowerCase())
        ) {
          return -1;
        }
        if (
          !a.city.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
          b.city.toLowerCase().includes(this.searchTerm.toLowerCase())
        ) {
          return 1;
        }
        return 0;
      });

      return foundAirports.slice(0, 10);
    }
  }
};
</script>
<style>
.found-airport {
  display: block;
  cursor: pointer;
}
.found-airport:hover {
  background-color: #f1f1f1;
}
</style>
