<template>
  <div class="c-edit-user-profile details">
    <div class="details__title px-5">
      <h1>{{ $t("profile.title") }}</h1>
    </div>
    <div class="container">
      <div class="columns">
        <div class="column is-half-desktop details__content__box">
          <form @submit.prevent="saveProfile">
            <p class="mb-3">
              {{ $t("profile.intro") }}
            </p>
            <label for="firstname">{{ $t("ReservationForm.firstname") }}</label>
            <input
              type="text"
              id="firstname"
              v-model="profile.passportFirstName"
              required
            />

            <label for="lastname">{{ $t("ReservationForm.lastname") }}</label>
            <input
              type="text"
              id="lastname"
              v-model="profile.passportLastName"
              required
            />

            <label for="nationality">{{
              $t("ReservationForm.nationality")
            }}</label>
            <input
              type="text"
              id="nationality"
              v-model="profile.nationality"
              required
            />

            <label for="dob">{{ $t("ReservationForm.dateOfBirth") }}</label>

            <Datepicker
              v-model="profile.birthDate"
              @update:modelValue="handleDOB"
              :maxDate="maxDateBday"
              :enableTimePicker="false"
              :format="formatDate"
              autoApply
              locale="nl"
            />
            <p class="pb-3">
              <i class="fa-solid fa-circle-info pr-3"></i
              >{{ $t("datepickerTip") }}
            </p>

            <label for="passport">{{
              $t("ReservationForm.passportNumber")
            }}</label>
            <input
              type="text"
              id="passportNumber"
              v-model="profile.passportNumber"
              :placeholder="passportNumberPlaceholder"
            />
            <p class="pb-3">
              <i class="fa-solid fa-circle-info pr-3"></i
              >{{ $t("passportNumberTip") }}
            </p>

            <label for="expiration">{{
              $t("ReservationForm.passportExpiration")
            }}</label>

            <Datepicker
              v-model="profile.passportExpirationDate"
              @update:modelValue="handleExpiration"
              :enableTimePicker="false"
              minDate="today"
              :maxDate="maxDatePassport"
              autoApply
              :format="formatDate"
              locale="nl"
            />
            <br />
            <br />
            <button class="button is-primary is-right" type="submit">
              {{ $t("save") }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <NotificationMessage
      :level="notification.level"
      :autohide="false"
      :title="notification.title"
      :message="notification.message"
      :show="notification.show"
      @toggle="toggleNotification"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import CommonHelper from "@/services/common.helper.js";
import moment from "moment";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      maxDatePassport: new Date(2023, 1, 1),
      maxDateBday: new Date(2013, 1, 1),
      passportNumberPlaceholder: "",
      profile: {
        passportFirstName: "",
        passportLastName: "",
        passportNumber: "",

        nationality: "",
        birthDate: "",
        passportExpirationDate: ""
      }
    };
  },
  async created() {
    let now = new Date();
    this.maxDateBday = new Date(
      now.getFullYear() - 15,
      now.getMonth(),
      now.getDate()
    );
    this.maxDatePassport = new Date(
      now.getFullYear() + 10,
      now.getMonth(),
      now.getDate()
    );

    this.fillProfile();
  },
  methods: {
    async fillProfile() {
      let url = `${this.AVAILABILITY_API_URL}/profile/profile-info`;

      const { data: profileInfo } = await this.axios.get(url);

      this.profile.passportFirstName = profileInfo.passportFirstName;
      this.profile.passportLastName = profileInfo.passportLastName;
      this.profile.nationality = profileInfo.nationality;
      this.passportNumberPlaceholder = profileInfo.passportNumber;

      this.profile.passportExpirationDate = moment(
        profileInfo.passportExpirationDate
      ).toDate();
      this.profile.birthDate = moment(profileInfo.birthDate).toDate();
    },
    handleDOB() {},
    handleExpiration() {},
    async saveProfile() {
      // TODO: Implement save logic
      const saveObject = {};
      saveObject.passportFirstName = this.profile.passportFirstName;
      saveObject.passportLastName = this.profile.passportLastName;
      saveObject.nationality = this.profile.nationality;

      if (this.profile.passportNumber && this.profile.passportNumber != "") {
        saveObject.passportNumber = this.profile.passportNumber;
      }
      saveObject.passportExpirationDate = moment(
        this.profile.passportExpirationDate
      ).format("YYYY-MM-DD");

      saveObject.birthDate = moment(this.profile.birthDate).format(
        "YYYY-MM-DD"
      );
      if (
        saveObject.passportExpirationDate.toLowerCase().indexOf("invalid") > -1
      ) {
        saveObject.passportExpirationDate = "";
      }
      if (saveObject.birthDate.toLowerCase().indexOf("invalid") > -1) {
        saveObject.birthDate = "";
      }
      // eslint-disable-next-line no-undef
      let url = `${this.AVAILABILITY_API_URL}/profile/save-profile`;

      const { status, data } = await this.axios.post(url, saveObject);
      console.log(status, data);
      if (status == 200) {
        this.notification.title = this.$t("ReservationForm.savedChanges");
        this.notification.message = this.$t(
          "ReservationForm.savedPassportdata"
        );
        this.notification.show = true;
        this.notification.level = "success";
        setTimeout(() => {
          this.notification.show = false;
        }, 30 * 1000);
      } else {
        this.notification.title = this.$t("ReservationForm.errorTitle");
        this.notification.message = this.$t("ReservationForm.errorMessage");
        this.notification.show = true;
        this.notification.level = "danger";

        setTimeout(() => {
          this.notification.show = false;
        }, 30 * 1000);
      }
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 1.5em;
}
.details__title {
  background: url("@/assets/images/profile-bg.jpg") center center no-repeat;
  background-size: cover;
}
</style>
