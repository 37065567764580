<template>
  <div class="details__title__stars">
    <i class="fas fa-star" v-for="n in parseInt(stars)" :key="n"></i>
    <i class="fas fa-star-half" v-if="stars % 1 > 0"></i>
  </div>
</template>

<script>
export default {
  name: "HotelStars",
  props: {
    stars: [Number, String]
  }
};
</script>
