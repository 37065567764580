<template>
  <div>
    <label for="employeeSearch">
      {{ $t("ReservationForm.find.employee") }}</label
    >
    <div class="control">
      <input
        class="input"
        name="employeeSearch"
        id="employeeSearch"
        v-model="employeeSearch"
        autocomplete="off"
        @keyup="findEmployee"
        type="text"
      />
    </div>
    <div class="content">
      <ul>
        <li v-for="found in foundEmployees" :key="found.id">
          <a @click="selectEmployee(found)" class="is-underlined">
            {{ found.firstName }} {{ found.lastName }}
            <template v-if="found.emailAddress">
              - {{ found.emailAddress }}
            </template>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { thisExpression } from "@babel/types";

export default {
  props: {
    businessUnits: {
      type: Array,
      default: () => []
    },

    preSelectedEmployees: {
      type: String,
      default: ""
    },
    selectedEmployees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      employeeSearch: "",
      foundEmployees: [],
      employees: []
    };
  },
  mounted() {
    this.extractEmployees();
    if (this.preSelectedEmployees) {
      let findableIds =
        this.preSelectedEmployees.indexOf(",") > -1
          ? this.preSelectedEmployees.split(",")
          : [this.preSelectedEmployees];
      findableIds.forEach((employeeId) => {
        const employee = this.employees.find((e) => e.id === employeeId);
        this.selectEmployee(employee);
      });
    }
  },
  methods: {
    selectEmployee(employee) {
      const selectedBusinessUnit = this.businessUnits.find((bU) => {
        return (
          bU.employees.find((e) => e.id === employee.id) &&
          bU.name !== "<NOUNIT>"
        );
      });
      this.track("hotelReservationGuestAdd", {
        additionType: "dropdown"
      });
      this.$emit("selectedEmployee", {
        employee: employee,
        businessUnit: selectedBusinessUnit
      });
      this.employeeSearch = "";
      this.foundEmployees = [];
    },
    clearEmployee() {
      this.employeeSearch = "";
    },
    extractEmployees() {
      this.employees = this.businessUnits.map((bU) => bU.employees).flat();
    },
    findBusinessUnit() {
      const matchString = this.businessUnitSearch.toLowerCase();
      this.foundBusinessUnits = this.businessUnits.filter((bU) =>
        bU.name.toLowerCase().includes(matchString)
      );
    },
    findEmployee() {
      const matchString = this.employeeSearch.toLowerCase();
      const selectedEmployeeIds = this.selectedEmployees.map((sE) => sE.id);
      this.foundEmployees = this.employees
        .filter((emp) => {
          const employeeAlreadySelected = selectedEmployeeIds.includes(emp.id);
          if (employeeAlreadySelected) {
            return false;
          }

          return (
            (emp.lastName &&
              emp.lastName.toLowerCase().includes(matchString)) ||
            (emp.firstName &&
              emp.firstName.toLowerCase().includes(matchString)) ||
            (emp.emailAddress &&
              emp.emailAddress.toLowerCase().includes(matchString)) ||
            (emp.id && emp.id.toLowerCase().includes(matchString))
          );
        })
        .slice(0, 20);
    }
  }
};
</script>

<style lang="scss" scoped></style>
