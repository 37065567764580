<template>
  <div class="container details__content__box">
    <div class="columns is-full-width" v-if="1 == 1">
      <div class="column is-one-quarter">
        <label for="airport">
          {{ $t("ReservationForm.departureAirport") }}
        </label>
      </div>
      <div class="column is-one-quarter">
        <label for="airport">{{ $t("ReservationForm.arrivalAirport") }}</label>
      </div>

      <div class="column">
        <label for="departure-date">{{
          $t("ReservationForm.departureDate")
        }}</label>
      </div>
      <div class="column is-one-fifth">
        <label for="departure-date">{{ $t("dayPart") }}</label>
      </div>
    </div>
    <template v-if="newLegAllowed">
      <div class="columns is-full-width is-multiline">
        <div class="column is-one-quarter">
          <FindAirport @airport-selected="setDepartureAirport" />
        </div>
        <div class="column is-one-quarter">
          <FindAirport @airport-selected="setArrivalAirport" />
        </div>
        <div class="column"></div>
        <div class="column is-one-fifth"></div>
      </div>
      <div class="columns is-full-width is-multiline">
        <div class="column is-one-quarter">
          {{ departureAirport?.name }}
        </div>
        <div class="column is-one-quarter">
          {{ arrivalAirport?.name }}
        </div>
        <div class="column">
          <Datepicker
            v-model="departureDate"
            :enableTimePicker="false"
            autoApply
            :format="formatDate"
            :minDate="minDepartureDate"
            locale="nl"
          />
        </div>
        <div class="column is-one-fifth">
          <div class="select">
            <select v-model="selectedDayPart">
              <option
                v-for="dayPart in dayParts"
                :value="dayPart.value"
                :key="dayPart.value"
              >
                {{ dayPart.label }}
              </option>
            </select>
          </div>
        </div>

        <div
          class="column is-full has-text-right"
          v-if="isValidLeg && selectedRoundtripType !== 'roundTrip'"
        >
          <a class="button" @click="addLeg">{{
            $t("ReservationForm.AddFlightLeg")
          }}</a>
        </div>
      </div>
    </template>
    <template v-if="selectedRoundtripType === 'roundTrip' && newLegAllowed">
      <div class="columns is-multiline">
        <div class="column is-one-quarter">
          {{ arrivalAirport?.name }}
        </div>
        <div class="column is-one-quarter">
          {{ departureAirport?.name }}
        </div>
        <div class="column">
          <Datepicker
            v-model="departureDateReturnFlight"
            :enableTimePicker="false"
            autoApply
            :format="formatDate"
            :minDate="departureDate"
            locale="nl"
          />
        </div>
        <div class="column is-one-fifth">
          <div class="select">
            <select v-model="selectedDayPartReturnFlight">
              <option
                v-for="dayPart in dayParts"
                :value="dayPart.value"
                :key="dayPart.value"
              >
                {{ dayPart.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="column is-full has-text-right" v-if="isValidLeg">
          <a class="button" @click="addReturnLeg">{{
            $t("ReservationForm.AddReturnFlightLeg")
          }}</a>
        </div>
      </div>
    </template>

    <template v-for="(leg, index) in legs" :key="index">
      <div class="columns is-full-width">
        <div class="column is-one-quarter">
          {{ leg.departureAirport.name }}
        </div>
        <div class="column is-one-quarter">
          {{ leg.arrivalAirport.name }}
        </div>
        <div class="column">
          {{ formatDate(leg.departureDate) }}
        </div>
        <div class="column is-one-fifth">
          {{ $t("ReservationForm." + leg.dayPart) }}
          &nbsp;&nbsp;&nbsp;
          <i
            class="fa-solid fa-delete-left is-danger"
            @click="removeLeg(leg)"
            style="cursor: pointer; color: #f14668"
          ></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FindAirport from "./FindAirport.vue";
import moment from "moment";

export default {
  components: {
    FindAirport
  },
  props: {
    selectedRoundtripType: {
      type: String,
      default: ""
    }
  },
  watch: {
    selectedRoundtripType() {
      this.legs = [];
    }
  },
  data() {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return {
      tomorrow,
      departureAirport: "",
      arrivalAirport: "",
      departureDate: "",
      departureDateReturnFlight: "",
      selectedDayPart: "",
      selectedDayPartReturnFlight: "",
      legs: [],
      dayParts: [
        { value: "morning", label: this.$t("ReservationForm.morning") },
        { value: "afternoon", label: this.$t("ReservationForm.afternoon") },
        { value: "evening", label: this.$t("ReservationForm.evening") }
      ],
      minDepartureDate: tomorrow
    };
  },
  computed: {
    isValidLeg() {
      if (this.selectedRoundtripType === "roundTrip") {
        return (
          this.departureAirport &&
          this.arrivalAirport &&
          this.selectedDayPart &&
          this.departureDate &&
          this.selectedDayPartReturnFlight &&
          this.departureDateReturnFlight
        );
      } else {
        return (
          this.departureAirport &&
          this.arrivalAirport &&
          this.selectedDayPart &&
          this.departureDate
        );
      }
    },

    newLegAllowed() {
      if (
        this.selectedRoundtripType === "oneWay" ||
        this.selectedRoundtripType === "roundTrip"
      ) {
        return this.legs.length === 0;
      }
      return true;
    }
  },
  methods: {
    removeLeg(leg) {
      this.legs = this.legs.filter((l) => l !== leg);
      this.$emit("legs-selected", this.legs);
    },
    clearLegs() {
      this.legs = [];
      this.minDepartureDate = this.tomorrow;
    },
    setDayPart(dayPart) {
      this.dayPart = dayPart;
    },
    setDepartureAirport(airport) {
      this.departureAirport = airport;
    },
    setArrivalAirport(airport) {
      this.arrivalAirport = airport;
    },

    addReturnLeg() {
      if (this.isValidLeg) {
        const leg = {
          departureAirport: this.departureAirport,
          arrivalAirport: this.arrivalAirport,
          departureDate: moment(this.departureDate).format("YYYY-MM-DD"),
          dayPart: this.selectedDayPart
        };

        this.legs.push(leg);
        const returnLeg = {
          departureAirport: this.arrivalAirport,
          arrivalAirport: this.departureAirport,
          departureDate: moment(this.departureDateReturnFlight).format(
            "YYYY-MM-DD"
          ),
          dayPart: this.selectedDayPartReturnFlight
        };

        this.legs.push(returnLeg);

        this.selectedDayPart = "";
        this.departureAirport = "";
        this.arrivalAirport = "";
        this.departureDate = "";
        this.departureDateReturnFlight = "";
        this.selectedDayPartReturnFlight = "";

        this.$emit("legs-selected", this.legs);
      }
    },
    addLeg() {
      if (this.isValidLeg) {
        const leg = {
          departureAirport: this.departureAirport,
          arrivalAirport: this.arrivalAirport,
          departureDate: moment(this.departureDate).format("YYYY-MM-DD"),
          dayPart: this.selectedDayPart
        };

        this.legs.push(leg);
        if (this.selectedRoundtripType === "multiLeg") {
          this.departureAirport = this.arrivalAirport;
          this.minDepartureDate = this.departureDate;
          this.arrivalAirport = "";
        } else {
          this.departureAirport = "";
          this.arrivalAirport = "";
        }

        this.selectedDayPart = "";
        this.departureDate = "";
        this.$emit("legs-selected", this.legs);
      }
    }
  }
};
</script>

<style scoped>
/* Add component-specific styles here */
</style>
