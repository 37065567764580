export default {
  "MfaPage": {
    "accountDataFetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your accountdata is being fetched"])},
    "checkMail2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email for a two-factor code"])},
    "errorInvalidTwofactorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code incorrect"])},
    "errorTwoFactorCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code has been entered incorrect too often, redirecting to login"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Authentication"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A two-factor code has been sent to your email address. Please enter it below to continue."])},
    "mfaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "toLoginForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login form"])},
    "twoFactorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Code"])}
  },
  "PASSWORD_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has expired, please change it using the link below the login button"])},
  "ReservationForm": {
    "AddFlightLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add leg"])},
    "AddReturnFlightLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add return flight"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "PREMIUM_ECONOMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Economy"])},
    "addAGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a guest"])},
    "addAPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a passenger"])},
    "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "arrivalAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Class"])},
    "changesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes allowed"])},
    "checkedLuggage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With checked luggage"])},
    "cityReservationIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a request for a reservation and we will see what we can do for you."])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "departureAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure"])},
    "departureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure date"])},
    "economy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again or contact us when the issue remains"])},
    "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
    "find": {
      "businessunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find BUSINESS_UNIT"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find employee"])}
    },
    "findBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a BUSINESS_UNIT"])},
    "findEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find an employee"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First names"])},
    "flightClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight class"])},
    "flightNotesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add important information here regarding the booking of the flight(s). If preferred, you can also add specific departure times or flight numbers here."])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching form"])},
    "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "multiLeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-city"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality (on the passport)"])},
    "noGuestsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No guests added"])},
    "noPassengersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No passengers added"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "notesPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply all information, such as special requests, for our agents"])},
    "oneWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One way"])},
    "passportExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport expiriation"])},
    "passportNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
    "personalReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a personal reservation and your personal data will automatically be stored with the reservation"])},
    "personalReservationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal reservation"])},
    "requestCityReservation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Place a city request for ", _interpolate(_named("city"))])},
    "requestReservation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request reservation at ", _interpolate(_named("name"))])},
    "reservationBase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Request a reservation for ", _interpolate(_named("count")), " night, from ", _interpolate(_named("startDate")), " untill ", _interpolate(_named("endDate"))]), _normalize(["Request a reservation for ", _interpolate(_named("count")), " nights, from ", _interpolate(_named("startDate")), " untill ", _interpolate(_named("endDate"))])])},
    "returnFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return date"])},
    "roundTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roundtrip"])},
    "savedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
    "savedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will get in touch "])},
    "savedPassportdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your passport data has been saved"])},
    "savedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been submitted"])},
    "selectedGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected guests"])},
    "selectedPassengers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected passengers"])},
    "unknownEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find the employee? Then you can enter the data manually."])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "adultsWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " adult"]), _normalize([_interpolate(_named("count")), " adults"]), _normalize([_interpolate(_named("count")), " adults"])])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "allDatesCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All dates are cancelled"])},
  "amenities": {
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
    "gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gym"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
    "meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting rooms"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking at the hotel"])},
    "pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool"])},
    "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hours reception"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])}
  },
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
  "backToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to search results"])},
  "basedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Based on a stay for ", _interpolate(_named("count")), " night, check-in ", _interpolate(_named("startDate")), " and check-out ", _interpolate(_named("endDate")), ". Price indication is per person."]), _normalize(["Based on a stay for ", _interpolate(_named("count")), " nights, check-in ", _interpolate(_named("startDate")), " and check-out ", _interpolate(_named("endDate")), ". Price indication is per person."])])},
  "basedOnChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can these these data in the reservation page"])},
  "booker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booker"])},
  "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking date"])},
  "bookingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking rules"])},
  "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast"])},
  "breakfastAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast included"])},
  "breakfastNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No breakfast included"])},
  "businessunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["business unit"])},
  "cateringOption": {
    "Borrel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinks"])},
    "Diner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
    "Frisdranken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soda's"])},
    "Koffie / Thee / Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee / Tea / Water"])},
    "Lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])}
  },
  "cityReservation": {
    "noneFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We weren't able to find anything in ", _interpolate(_named("name")), "..."])},
    "notFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Didn't find the right place in ", _interpolate(_named("name")), "?"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place request"])},
    "placeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place a city request and we will see what we can do for you!"])},
    "placeRequestNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je een stadsverzoek doet, dan kunnen wij kijken of we een alternatief kunnen bieden"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "collapseOfferless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse hotels without offers"])},
  "comingNightNoPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're searching for a hotel room for tonight, therefor prices might not show. You can still book though; we will try to do our best to make the reservation."])},
  "complexBooking": {
    "breakfastRefundableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a reservation with breakfast and refundable?"])},
    "changeDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change data"])},
    "chosenData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen data"])},
    "chosenFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've chosen for"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also make a reservation for a longer period with time in between bookings. You won't see any prices then."])},
    "launchModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a reservation for a longer period with time in between bookings"])},
    "modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longer reservation"])},
    "modalHeaderStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the period"])},
    "modalHeaderStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the days"])},
    "modalHeaderStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the check-in days"])},
    "modalHeaderStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the input"])},
    "step1Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the day of the first check-in and last last check-out. You can navigate the calendar with the arrows."])},
    "step2Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected the days on which the guest will generally check in. You can set exceptions in the next step.."])},
    "step3Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These days have been prefilled. You can set excemptions (days off or days on) by clicking on the day."])},
    "step4Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the selected dates"])}
  },
  "confirmCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation request"])},
  "confirmCancellationRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request for cancellation has been sent, you will receive a confirmation by email."])},
  "confirmCancellationRequestedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request made"])},
  "confirmationTextCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to request cancellation?"])},
  "costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs"])},
  "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "dateRangeTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Try selecting a smaller date range"])},
  "dateTimeSelection": {
    "Dag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "Dagdeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of day"])},
    "Meerdere dagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple days"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ending time"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "startDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date from"])},
    "startDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date to"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting time"])}
  },
  "datepickerTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip: you can click the year or month in the datepicker and easily select another year or month"])},
  "dayPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of day"])},
  "directBookingNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: mentioned prices are possible based on a hypethical stay, please check and confirm your dates before making the reservation"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
  "distanceFromCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("m")), "m from centre ", _interpolate(_named("city"))])},
  "distanceFromCityAbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the city center"])},
  "distanceFromCityLarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("km")), "km from ", _interpolate(_named("city"))])},
  "doYouWishToMakeACityReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to make a city reservation?"])},
  "editReservationMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "enterDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date range"])},
  "enteredAllWishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you enter your preferences? Submit them and we will see what we can offer you!"])},
  "error": {
    "businessunitMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a BUSINESS_UNIT"])},
    "customFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter or select values for all fields marked with an *"])},
    "employeeMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an employee"])},
    "genericIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following data was entered incorrectly or is missing"])},
    "genericTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please checked the entered data"])},
    "locationMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a location"])},
    "noLegs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add flights"])},
    "noOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an offer"])}
  },
  "errorLoggingIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error logging in; try again later. This is a system error; no error in your credentials"])},
  "errorLoggingInCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error logging in, please check your credentials"])},
  "expedia": {
    "AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC"])},
    "ACCESSIBLE_ROLL_IN_SHOWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll-in Shower "])},
    "AIRPORT_TRANSPORTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport transportation"])},
    "ALL_INCLUSIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All inclusive"])},
    "ARCADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arcade"])},
    "BABYSITTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Babysitting"])},
    "BALCONY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
    "BAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
    "BARBECUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbecue"])},
    "BATHROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathroom"])},
    "BEDROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedroom"])},
    "BOWLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bowling"])},
    "BREAKFAST_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast available"])},
    "BREAKFAST_INCLUDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast included"])},
    "BUSINESS_SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business services"])},
    "CHILDREN_CLUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids club"])},
    "CRIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crib"])},
    "DRYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dryer"])},
    "ELECTRIC_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric car charging station"])},
    "ELEVATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevator"])},
    "ENTERTAINMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "EXTRA_BED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra bed"])},
    "FIREPLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fireplace"])},
    "FITNESS_EQUIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness equipment"])},
    "FOOD_AND_DRINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food &amp; drinks"])},
    "FREE_PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free parking"])},
    "FRONTDESK_24_HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontdesk 24-hour"])},
    "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grocery"])},
    "HEATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heater"])},
    "HOT_TUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot tub"])},
    "HOUSEKEEPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housekeeping"])},
    "INTERNET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
    "KIDS_POOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids pool"])},
    "KITCHEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen"])},
    "LAUNDRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry"])},
    "LIVING_AREA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living area"])},
    "MICROWAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microwave"])},
    "MINI_GOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minigolf"])},
    "MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More..."])},
    "NO_SMOKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No smoking"])},
    "OUTDOOR_GRILL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor grill"])},
    "OUTDOOR_SPACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor space"])},
    "PARKING_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking available"])},
    "PARKING_INCLUDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking included"])},
    "PATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patio"])},
    "PETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets allowed"])},
    "PLAYGROUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground "])},
    "POOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool"])},
    "RESTAURANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
    "ROOM_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roomservice"])},
    "SOUNDPROOF_ROOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soundproof room"])},
    "SPA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spa"])},
    "SPECIAL_DEAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special deal"])},
    "TENNIS_COURT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis court"])},
    "TOYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toys"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])},
    "WASHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["washer"])},
    "WASHER_AND_DRYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washer and dryer"])},
    "WATERSLIDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterslide"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])},
    "activitiesNearby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities nearby "])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
    "beach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beach"])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
    "businessServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business services"])},
    "childrenAndExtraBedPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children &amp; extra beds"])},
    "cleanlinessAndSafety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleanliness and safety"])},
    "climateControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Climate control"])},
    "conveniences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convencies"])},
    "dining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dining"])},
    "entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment"])},
    "familyFriendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family friendly"])},
    "foodAndDrink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food and drinks"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "guestServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest services"])},
    "internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kichen"])},
    "knowBeforeYouGo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know before you go!"])},
    "langsSpoken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages spoken"])},
    "laundry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laundry"])},
    "livingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livingspaces"])},
    "locationHighlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location highlights"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more..."])},
    "outdoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor"])},
    "outdoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoors"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "petPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet Policy"])},
    "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets"])},
    "poolspa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool &amp; spa"])},
    "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety"])},
    "servicesAndConveniences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services &amp; conveniences"])},
    "ski": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spa"])},
    "specialInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special instructions"])},
    "suitabilityAccessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitability and accessibility"])},
    "thingsToDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Things to do"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])}
  },
  "facilitiesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
  "facilityOption": {
    "Beamer/Scherm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beamer/Display"])},
    "Flipover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flipover"])},
    "Geluidsinstallatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound installation"])},
    "Microfoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone"])}
  },
  "favoriteHotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite hotels"])},
  "favoriteHotelsNoHotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your most booked hotels will appear here."])},
  "findAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find airport"])},
  "findLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find location"])},
  "flights": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a flight"])}
  },
  "forgot.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future"])},
  "genericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
  "greenKeyFilter": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Green Key accomodations?"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
  "guestReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests reviews"])},
  "hasCancelledDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This booking has cancelled dates"])},
  "hasGreenKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has a Green Key"])},
  "hasGreenKeyExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Key is an international sustainability label for companies in the reactive, leisure and business sector. The accommodations with a Green Key certificate do a lot regarding sustainability, without their guests compromising on comfort and quality. They go a step further than the normal law and regulations."])},
  "hasGreenKeyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This accomodation has a Green Key"])},
  "hasNoGreenKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not have a Green Key"])},
  "hideCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide cancelled"])},
  "hotelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel address"])},
  "hotelAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel amenities"])},
  "hotelLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel data is being fetched"])},
  "hotelsLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels are being fetched"])},
  "inOutCheckTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In- &amp; outcheck times"])},
  "inTheNeighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the neighbourhood"])},
  "loadingCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hotels found"])},
  "loadingStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percentage")), "% loaded"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginPage": {
    "accountDataFetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your accountdata is being fetched"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log on to the portal"])}
  },
  "loginStatus": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "wantToLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to log out?"])},
    "yesLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, log me out"])}
  },
  "meetingReservation": {
    "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your entered data, try again or contact us when the issue remains"])},
    "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a meeting room reservation"])},
    "iNeedHotelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I need hotel rooms"])},
    "needHotelRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need hotelrooms?"])},
    "numberDuoRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of duo rooms"])},
    "numberSingleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of single rooms"])},
    "roomLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room layout"])},
    "savedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will get in touch "])},
    "savedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been submitted"])}
  },
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "nav": {
    "bookingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking overview"])},
    "flightForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flights"])},
    "meetingReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting reservation"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "nightsWithLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " night"]), _normalize([_interpolate(_named("count")), " nights"])])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noGoBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, return"])},
  "noHotelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No content found for this hotel"])},
  "noHotelsFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please change your search parameters"])},
  "noHotelsFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hotels were found"])},
  "noPriceFoundRequestAnyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No prices found; You cannot make a reservation for this hotel. We suggest finding another hotel or contacting our Corporate Travel Agents."])},
  "noPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers found"])},
  "noReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviews"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter extra information of wishes for your stay"])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
  "offerIsNotRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer is not refundable"])},
  "offerIsRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer is refundable"])},
  "offerNotOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This offer is online not available. However, it is almost always possible for us to book under these conditions."])},
  "offerNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "otherOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other options"])},
  "passportNumberTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only enter this number if you wish to change it"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
  "perNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per night"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "popularAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular amenities"])},
  "priceFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "profile": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the data exactly as in the passport"])},
    "metamenuLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit passport data"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change passport data"])}
  },
  "projectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project number"])},
  "rebook": {
    "doubleWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected parameters which might result in a double booking, please check before confirming"])},
    "rebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-book hotel"])},
    "sameBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same BUSINESS_UNIT"])},
    "sameDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same date"])},
    "sameGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same guest"])}
  },
  "recommended.hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
  "recommendedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended hotels are refundable<br /> and include breakfast"])},
  "refundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable"])},
  "refundableFilter": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable?"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "requestCancellation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Request cancellation for ", _interpolate(_named("firstname")), " ", _interpolate(_named("lastname"))])},
  "requestCancellationAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request cancellation for all"])},
  "requestCancellationAllEmployees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request cancellation for everyone"])},
  "requestCancellationSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request cancellation for selected"])},
  "requestReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request reservation"])},
  "reservationOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations"])},
  "reservationOverviewLimitedResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The returned set is large and might not contain all reservations from the requested period. Please narrow down the date range if you cannot find the selected reservation"])},
  "reservationOverviewMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations can be changed or cancelled via this page or through e-mail via zakelijk&#64;lookingforbooking.com"])},
  "reservationoverview": {
    "externalBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This reservation was made outside of this portal and can therefor not be rebooked"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching Bookings"])},
    "omniSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for employee, city or hotel"])},
    "startdate_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkin-in to"])},
    "startdate_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in from"])}
  },
  "resultsPage": {
    "budget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Budget per night is EUR ", _interpolate(_named("budget"))])},
    "countHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hotelCount")), " hotels in ", _interpolate(_named("name"))])},
    "priceDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All mentioned prices are per night, per person and indicative."])},
    "sortDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to centre"])},
    "sortRecommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended + distance to centre"])},
    "sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])}
  },
  "reviewCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 reviews"]), _normalize(["1 review"]), _normalize([_interpolate(_named("numberOfReviews")), " reviews"])])},
  "reviews": {
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor"])},
    "reallyGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Really good"])},
    "terrible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrible"])},
    "veryGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very good"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room amenities"])},
  "roomLayouts": {
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "Boardroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boardroom"])},
    "Cabaret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabaret"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "School": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "Square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Square"])},
    "Theater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theater"])},
    "U-Shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U-Shape"])}
  },
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "saveAndLoadPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and load new prices"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchFilters": {
    "applyFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filters"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change filters"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "distanceToCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to centre"])},
    "distanceToLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To ", _interpolate(_named("n")), " km"])},
    "distanceToLabelAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set automatically"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "hotelNameOrChain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for hotel name or chain"])},
    "hotelNameOrChainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or chain"])},
    "numberOfPersons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# adults"])},
    "numberOfPersonsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of rooms"])},
    "numberOfRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "starCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# of stars"])}
  },
  "searchPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do you want to go?"])}
  },
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "selectCheckinAndCheckOutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First select your checkin date and then your checkoutdate in the same calendar."])},
  "selectDatePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date or period"])},
  "selectYourOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your offer"])},
  "selectedBusinessUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected BUSINESS_UNIT"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send flight request"])},
  "serverErrorTryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: The server returned an error; try again later"])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "showOnMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show on map"])},
  "stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay"])},
  "stayPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in & check-out"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "viewAllPhotosText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["View 1 photo"]), _normalize(["View all ", _interpolate(_named("count")), " photo's"])])},
  "viewHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View hotel"])},
  "warningOverBudget": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Warning: this offer exceeds your budget of EUR ", _interpolate(_named("budget")), " per night"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])}
}