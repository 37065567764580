<template>
  <div class="c-location-finder">
    <label for="searchInput">Locatie</label>
    <div class="input-icon">
      <div class="input-icon--wrapper">
        <i class="fas fa-map-marker" aria-hidden="true"></i>
      </div>
      <input
        type="text"
        v-model="searchInput"
        v-on:keyup="getResults"
        id="searchInput"
        class="c-location-finder__input"
        placeholder="Locatie"
        :class="{ 'c-location-finder__input--loading': isLoading }"
        autocomplete="off"
      />
    </div>

    <div class="c-location-finder__results">
      <ul
        class="c-location-finder__result-list"
        v-if="result && result.length == 0"
      >
        <li class="c-location-finder__result-list--item">
          {{ $t("nothingFound") }}
        </li>
      </ul>

      <ul
        class="c-location-finder__result-list"
        v-if="result && result?.length > 0"
      >
        <li
          class="c-location-finder__result-list--item"
          v-for="item in result"
          :key="item"
          v-on:click="selectResult(item)"
        >
          <span class="c-location-finder__result-list--title">
            <span class="c-location-finder__result-list--icon">
              <i v-if="item.featureCode === 'HTL'" class="fas fa-hotel"></i>
              <i v-else class="fas fa-city"></i>
            </span>
            {{ item.name }}</span
          >
          <span class="c-location-finder__result-list--description">
            {{ item.admin1 }}, {{ item.country }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import commonHelper from "@/services/common.helper";

export default {
  name: "LocationFinder",
  props: {
    location: Object,
    onlyLocations: Boolean
  },
  emits: ["selectedLocation"],
  data() {
    return {
      callId: null,
      isLoading: false,
      result: null,
      searchInput: ""
    };
  },
  mounted() {
    if (this.location) {
      this.searchInput = this.location.name;
    }
  },
  methods: {
    async getResults() {
      if (this.searchInput.length >= 1) {
        if (this.callId) {
          clearTimeout(this.callId);
        }
        this.callId = setTimeout(async () => {
          this.isLoading = true;
          const data = await commonHelper.getLocationsFromSearch(
            this.searchInput
          );

          this.result = data.filter((h) => h.featureCode !== "HTL");
          this.isLoading = false;
        }, 750);
      } else {
        this.result = [];
      }
    },
    selectResult(locationItem) {
      this.searchInput = locationItem.name;
      this.result = null;

      this.$emit("selectedLocation", locationItem.locationPath);
    }
  }
};
</script>
