<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t("complexBooking.modalHeader") }} -
          {{ $t("complexBooking.modalHeaderStep" + step) }}
        </p>
        <button
          class="delete"
          @click="showModal = false"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns content is-multiline">
          <div class="column is-full" v-if="step == 1">
            <p class="intro-text">
              {{ $t("complexBooking.step1Text") }}
            </p>
            <Datepicker
              v-model="period"
              range
              :minDate="new Date()"
              :maxDate="maxDate"
              @update:modelValue="handleDate"
              :enableTimePicker="false"
              inline
              autoApply
              :multi-calendars="{ count: 4 }"
              locale="nl"
            />

            <button
              :disabled="!period"
              class="button is-primary mt-4"
              @click="step = 2"
            >
              {{ $t("complexBooking.continue") }} &raquo;
            </button>
          </div>
          <div class="column is-full" v-if="step == 2">
            <p class="intro-text">
              {{ $t("complexBooking.step2Text") }}
            </p>
            <div class="columns c-days-selection">
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[1]" />
                  {{ $t("monday") }}</label
                >
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[2]" />
                  {{ $t("tuesday") }}</label
                >
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[3]" />
                  {{ $t("wednesday") }}</label
                >
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[4]" />
                  {{ $t("thursday") }}</label
                >
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[5]" />
                  {{ $t("friday") }}</label
                >
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[6]" />
                  {{ $t("saturday") }}
                </label>
              </div>
              <div class="column">
                <label>
                  <input type="checkbox" v-model="days[0]" />
                  {{ $t("sunday") }}</label
                >
              </div>
            </div>

            <button
              class="button is-primary mt-4"
              @click="generateDaysFromSelection()"
            >
              {{ $t("complexBooking.continue") }} &raquo;
            </button>
          </div>
          <div class="column is-full" v-if="step == 3">
            <p class="intro-text">
              {{ $t("complexBooking.step3Text") }}
            </p>
            <Datepicker
              inline
              autoApply
              :minDate="new Date()"
              v-model="selectedDates"
              :start-date="selectedDates[0]"
              :enableTimePicker="false"
              :focus-start-date="true"
              :multi-dates="true"
              :multi-calendars="{ count: numberOfMonths }"
            />

            <button class="button is-primary mt-4" @click="confirmDates()">
              {{ $t("complexBooking.continue") }} &raquo;
            </button>
          </div>
        </div>
        <div v-if="step == 4">
          <div class="columns">
            <div class="column">
              <p class="intro-text">{{ $t("complexBooking.step4Text") }}</p>

              <ul class="mt-3">
                <li v-for="selectedDate in selectedDates" :key="selectedDate">
                  <span class="date-label--day">
                    {{ formatDateLabelDay(selectedDate) }}
                  </span>

                  {{ formatDateLabel(selectedDate) }}
                </li>
              </ul>
              <button class="button is-warning mt-4" @click="step = 3">
                &laquo; {{ $t("complexBooking.changeDates") }}
              </button>
            </div>
            <div class="column">
              <p class="mt-3">
                {{ $t("complexBooking.breakfastRefundableHeader") }}
              </p>
              <label>
                <input type="checkbox" v-model="breakfast" />
                {{ $t("breakfast") }}
              </label>
              <br />
              <label>
                <input type="checkbox" v-model="refundable" />
                {{ $t("refundable") }}
              </label>
              <br />
              <button class="button is-primary mt-4" @click="confirmAll()">
                {{ $t("complexBooking.confirm") }} &check;
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- footer class="modal-card-foot"></!-->
    </div>
    <button
      @click="showModal = false"
      class="modal-close is-large"
      aria-label="close"
    ></button>
  </div>
</template>

<script>
import moment from "moment";
import { nl, en } from "date-fns/locale";

import Datepicker from "@vuepic/vue-datepicker";
export default {
  name: "ComplexBooking",
  components: { Datepicker },
  data() {
    return {
      locale: nl,
      showModal: false,
      step: 1,
      numberOfMonths: 4,
      days: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        0: false
      },
      maxDate: moment().add(7, "months").toDate(),
      period: null,
      selectedDates: [],
      breakfast: true,
      refundable: true
    };
  },
  methods: {
    initProcess() {
      this.selectedDates = [];
      this.period = null;
      this.step = 1;
      this.showModal = true;
    },
    confirmDates() {
      this.selectedDates.sort((a, b) => (a.getTime() < b.getTime() ? -1 : 1));

      this.step = 4;
    },
    confirmAll() {
      const emitData = {
        selectedDates: this.selectedDates,
        breakfast: this.breakfast,
        refundable: this.refundable
      };

      this.$emit("selectedDates", emitData);
      this.showModal = false;
    },
    handleDate(date) {
      const [startDate, endDate] = date;
    },
    generateDaysFromSelection() {
      const start = moment(this.period[0]).add(-1, "days");
      const end = moment(this.period[1]);
      const current = start.clone();
      const selectedDates = [];
      while (!current.add("1", "days").isAfter(end)) {
        const currentday = current.day();
        if (this.days[currentday]) {
          selectedDates.push(current.clone().toDate());
        }
      }
      if (selectedDates.length == 0) {
        alert("Selecteer minimaal 1 dag");
        return;
      } else {
        this.selectedDates = selectedDates;

        const diffInMonths = Math.ceil(
          end.date(28).diff(start.date(1), "months", true)
        );

        this.numberOfMonths = diffInMonths;

        this.step = 3;
      }
    }
  },
  computed: {
    // Your computed properties here
  },
  mounted() {}
};
</script>

<style scoped>
.intro-text {
  border: 1px solid grey;
  padding: 12px;
  background-color: #f1f1f1;
}
.days-selection {
  max-width: 880px;
}
@media screen and (min-width: 769px) {
  .modal-card,
  .modal-content {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 90vw;
    max-width: 1400px;
  }
}
</style>
