<template>
  <div class="results-list__item__stars">
    <i v-for="n in parseInt(stars)" :key="n" class="fas fa-star"></i>
    <i class="fas fa-star-half" v-if="stars % 1 > 0"></i>
  </div>
</template>

<script>
export default {
  name: "StarsLabel",
  props: {
    stars: Number
  }
};
</script>
