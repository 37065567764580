<template>
  <div class="main-sso">
    <div class="login-wrapper p-5">
      <div class="columns is-multiline">
        <div class="field column is-full">
          <h1 class="is-h1 title">SSO Login</h1>
        </div>
      </div>
    </div>
    <notification-message
      :level="'warning'"
      :title="notificationTitle"
      :message="notificationMessage"
      :show="showNotification"
    />
  </div>
</template>

<script>
import NotificationMessage from "./components/shared/NotificationMessage.vue";
import CommonHelper from "@/services/common.helper.js";

export default {
  components: { NotificationMessage },
  name: "Login",
  props: {},
  data() {
    return {
      showNotification: true,
      notificationTitle: "",
      notificationMessage: ""
    };
  },
  async mounted() {
    let { companyOrSessionId, SessionSecondsValid } = this.$route.params;
    if (companyOrSessionId && !SessionSecondsValid) {
      this.getSSOUrl(companyOrSessionId);
    } else if (companyOrSessionId && SessionSecondsValid) {
      this.loginWithSessionId(companyOrSessionId, SessionSecondsValid);
    } else {
      console.log("No company or session id found");
    }
  },
  methods: {
    getSSOUrl(company) {
      const url = `${this.AVAILABILITY_API_URL}/authentication/ssoUrl?customerId=${company}`;
      this.axios
        .get(url)
        .then(({ data }) => {
          window.location.href = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loginWithSessionId(sessionId, SessionSecondsValid) {
      const url = `${this.AVAILABILITY_API_URL}/authentication/setcookie`;
      sessionId = decodeURIComponent(sessionId);
      const { data, status } = await this.axios.post(url, {
        sessionId,
        SessionSecondsValid
      });

      if (status == 200) {
        CommonHelper.saveSession(data?.userName || "Gebruiker");
        await this.getCompanyInfo();
        this.sleep(500);
        await this.injectCustomCss();

        this.$router.push({
          name: this.PAGES.LandingPage
        });
      } else {
        this.notificationTitle = "Geen geldige sessie gevonden";
        this.notificationMessage = "De sessie is niet meer geldig.";
        this.showNotification = true;
      }
    }
  }
};
</script>

<style lang="scss">
.login-wrapper {
  background: white;
  margin: 0 12px;
  display: flex;
  box-shadow: -10px -2px 10px 3px #36363661;
  align-self: center;
  max-width: 600px;
  border-radius: 8px;
}
.main-sso {
  margin: 0;
  height: 100vh;
  background: center no-repeat;
  background-image: var(--brand-background-image);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
