<template>
  <div class="reservation-form-price">
    <h5 class="title is-5">{{ $t("selectYourOffer") }}</h5>
    <div class="content mb-3">
      <table class="table">
        <tr
          v-for="(offer, $index) in lowestOffers"
          :key="offer.offerName"
          class="details__price--extra-offers pb-2 mb-3 control is-size-7-touch"
        >
          <td>
            <label class="radio">
              <input
                type="radio"
                name="selectedOffer"
                :id="$index"
                @change="setOffer(offer)"
                :value="offer.offerName"
              />

              {{ offer.isPlaceholder ? $t("offerNotice") : getPrice(offer) }}
            </label>
          </td>
          <td>
            <label :for="$index">
              <span class="pl-4">
                {{
                  offer.isRefundable
                    ? $t("offerIsRefundable")
                    : $t("offerIsNotRefundable")
                }}
              </span>
            </label>
          </td>
          <td>
            <label :for="$index">
              <span class="pl-4">
                {{
                  offer.isBreakfastIncluded
                    ? $t("breakfastAvailable")
                    : $t("breakfastNotAvailable")
                }}</span
              >
            </label>
          </td>
        </tr>
      </table>
    </div>
    <template v-if="!(suppliersWithOffers && suppliersWithOffers.length)">
      <p class="mb-3">
        {{ $t("noPriceFoundRequestAnyway") }}
      </p>
    </template>
  </div>
</template>
<script>
import HotelHelper from "@/services/hotel.helper.js";
export default {
  name: "SelectOffer",
  methods: {
    getPrice(offer) {
      return HotelHelper.getPrice(offer);
    },
    getPricePerNight(offer) {
      return HotelHelper.getPricePerNight(offer, this.nights);
    },
    setOffer(offer) {
      this.$emit("selectedOffer", { offer });
    }
  },
  data() {
    return {
      hasFixedPriceOffer: false,
      lowestOffer: null,
      selectedOffer: null,
      suppliersWithOffers: []
    };
  },
  watch: {
    suppliers: {
      handler(newValue) {
        let priceSummary = HotelHelper.getPriceSummary(newValue);
        this.hasFixedPriceOffer = HotelHelper.hasFixedPriceOffer(newValue);
        this.lowestOffer = priceSummary.lowestOffer;
        let lowestOffers = priceSummary.lowestOffers;
        let fauxObject = {
          isRefundable: false,
          isBreakfastIncluded: false,
          price: null,
          isPlaceholder: true
        };
        this.lowestOffers = [];
        let offer;
        if (lowestOffers?.refundable?.breakfastIncluded) {
          this.lowestOffers.push(lowestOffers?.refundable?.breakfastIncluded);
        } else {
          offer = { ...fauxObject };
          offer.isRefundable = true;
          offer.isBreakfastIncluded = true;
          this.lowestOffers.push(offer);
        }

        if (lowestOffers?.refundable?.breakfastNotIncluded) {
          this.lowestOffers.push(
            lowestOffers?.refundable?.breakfastNotIncluded
          );
        } else {
          if (!this.hasFixedPriceOffer) {
            offer = { ...fauxObject };
            offer.isRefundable = true;
            offer.isBreakfastIncluded = false;
            this.lowestOffers.push(offer);
          }
        }
        if (lowestOffers?.nonRefundable?.breakfastIncluded) {
          this.lowestOffers.push(
            lowestOffers?.nonRefundable?.breakfastIncluded
          );
        } else {
          if (!this.hasFixedPriceOffer) {
            offer = { ...fauxObject };
            offer.isRefundable = false;
            offer.isBreakfastIncluded = true;
            this.lowestOffers.push(offer);
          }
        }
        if (lowestOffers?.nonRefundable?.breakfastNotIncluded) {
          this.lowestOffers.push(
            lowestOffers?.nonRefundable?.breakfastNotIncluded
          );
        } else {
          if (!this.hasFixedPriceOffer) {
            offer = { ...fauxObject };
            offer.isRefundable = false;
            offer.isBreakfastIncluded = false;
            this.lowestOffers.push(offer);
          }
        }
        if (this.breakfastIncluded == "true") {
          this.lowestOffers = this.lowestOffers.filter(
            (p) => p.isBreakfastIncluded
          );
        }

        this.suppliersWithOffers = priceSummary.suppliersWithOffers;
      },
      immediate: true
    }
  },
  props: {
    suppliers: Array,
    nights: String,
    breakfastIncluded: String
  }
};
</script>
