<template>
  <div
    class="search__results__filter is-flex is-justify-content-center is-justify-content-center is-align-content-center"
  >
    <div
      class="search__results__filter__overlay"
      :class="{ open: filtersOpen }"
    >
      <form @submit.prevent="applyFilters()">
        <div class="columns is-multiline mt-1">
          <div class="column is-three-fifths pr-5 is-align-content-start">
            <div class="search__results__filter__wrapper--date">
              <label for="date">{{ $t("period") }}</label>

              <Datepicker
                v-model="date"
                range
                :minDate="new Date()"
                @update:modelValue="handleDate"
                :enableTimePicker="false"
                inline
                autoApply
                multiCalendars
                locale="nl"
              />
              <p class="pt-3">
                {{ $t("selectCheckinAndCheckOutDate") }}
              </p>
            </div>

            <div
              v-if="location"
              class="search__results__filter__wrapper--radius"
            >
              <div class="columns">
                <div class="column is-three-quarters">
                  <LocationFinder
                    :location="location"
                    :only-locations="true"
                    @selectedLocation="selectedLocation"
                  />
                </div>
                <div class="column">
                  <label for="radius">{{ $t("distance") }}</label>
                  <select v-model="filters.radius" id="radius">
                    <option selected value="0">
                      {{ $t("searchFilters.distanceToLabelAuto") }}
                    </option>
                    <option value="1">
                      {{ $t("searchFilters.distanceToLabel", { n: 1 }) }}
                    </option>
                    <option value="3">
                      {{ $t("searchFilters.distanceToLabel", { n: 3 }) }}
                    </option>
                    <option value="5">
                      {{ $t("searchFilters.distanceToLabel", { n: 5 }) }}
                    </option>
                    <option value="10">
                      {{ $t("searchFilters.distanceToLabel", { n: 10 }) }}
                    </option>
                    <option value="20">
                      {{ $t("searchFilters.distanceToLabel", { n: 20 }) }}
                    </option>
                    <option value="40">
                      {{ $t("searchFilters.distanceToLabel", { n: 40 }) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="search__results__filter__wrapper--chain">
              <label for="hotelName">{{
                $t("searchFilters.hotelNameOrChainTitle")
              }}</label>
              <input
                id="hotelName"
                type="text"
                :placeholder="$t('searchFilters.hotelNameOrChain')"
                v-model="filters.hotelName"
              />
            </div>
            <div class="columns">
              <div class="column is-half">
                <label for="adults">
                  {{ $t("searchFilters.numberOfPersonsTitle") }}
                </label>
                <div class="input-icon">
                  <div class="input-icon--wrapper">
                    <i class="fas fa-person" aria-hidden="true"></i>
                  </div>
                  <input
                    id="adults"
                    type="number"
                    min="1"
                    max="8"
                    :placeholder="$t('searchFilters.numberOfPersons')"
                    v-model="filters.adults"
                  />
                </div>
              </div>
              <div class="column" hidden>
                <label for="rooms">
                  {{ $t("searchFilters.numberOfRoomsTitle") }}
                </label>
                <div class="input-icon">
                  <div class="input-icon--wrapper">
                    <i class="fas fa-tent" aria-hidden="true"></i>
                  </div>
                  <input
                    id="rooms"
                    type="number"
                    min="1"
                    :max="filters.adults"
                    :placeholder="$t('searchFilters.numberOfRooms')"
                    v-model="filters.rooms"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="column is-two-fifths">
            <!-- <div class="search__results__filter__wrapper--cancellation">
              <RefundableFilter @hasRefundableChanged="hasRefundableChanged" />
            </div> -->
            <div class="search__results__filter__wrapper--greenkey">
              <GreenKeyFilter @greenKeyChanged="greenKeyChanged" />
            </div>
            <div class="search__results__filter__wrapper--stars">
              <StarsFilter @starsChanged="starsChanged" />
            </div>
            <div class="search__results__filter__wrapper--score">
              <RatingFilter @ratingChanged="ratingChanged" />
            </div>
            <div class="search__results__filter__wrapper--amenities">
              <AmenitiesFilter @amenitiesChanged="amenitiesChanged" />
            </div>
          </div>
          <div class="column is-full">
            <div class="search__results__filter__wrapper--submit">
              <button class="c-button--small--fullwidth">
                {{ $t("searchFilters.applyFilters") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StarsFilter from "./StarsFilters.vue";
import RatingFilter from "./RatingFilter.vue";
import AmenitiesFilter from "./AmenitiesFilter.vue";
import LocationFinder from "./LocationFinder.vue";
//import RefundableFilter from "./RefundableFilter";
import GreenKeyFilter from "./GreenKeyFilter.vue";
import Datepicker from "@vuepic/vue-datepicker";

import CommonHelper from "@/services/common.helper";
import moment from "moment";

export default {
  name: "Filters",
  components: {
    Datepicker,
    StarsFilter,
    RatingFilter,
    AmenitiesFilter,
    GreenKeyFilter,
    LocationFinder
    //RefundableFilter
  },
  props: {
    filtersOpen: Boolean,
    location: {},
    variables: {}
  },
  data() {
    return {
      date: null,
      arrivalDate: new Date(),
      departureDate: new Date(),
      minDepartureDate: new Date(),
      filters: {
        radius: 0,
        stars: {},
        hotelName: "",
        score: {},
        adults: 1,
        rooms: 1,
        refundable: true,
        greenKey: false,
        amenities: {},
        checkin: null,
        nights: 1
      },
      locationPath: ""
    };
  },
  methods: {
    handleDate(date) {
      const [startDate, endDate] = date;
      let nights = moment(endDate).diff(moment(startDate), "days") + "";
      if (nights == 0) nights = 1;

      this.filters.checkin = startDate;
      this.filters.nights = nights;
    },
    getDateRange() {
      let dateRange = CommonHelper.getDateRange(
        this.variables.checkin,
        this.variables.nights
      );
      return dateRange;
    },
    openFilters() {
      this.$emit("toggleFilters");
    },
    closeFilters() {
      this.$emit("closeFilters");
    },
    applyFilters() {
      this.$emit("applyFilters", this.filters);
    },
    amenitiesChanged(amenities) {
      this.filters.amenities = amenities;
    },
    ratingChanged(score) {
      this.filters.score = score;
    },
    hasRefundableChanged(refundable) {
      this.filters.refundable = refundable;
    },
    starsChanged(stars) {
      this.filters.stars = stars;
    },
    greenKeyChanged(greenKey) {
      this.filters.greenKey = greenKey;
    },
    selectedLocation(locationPath) {
      this.locationPath = locationPath;
      this.$emit("selectedLocation", locationPath);
    },
    setDateForInput() {
      const range = this.getDateRange();
      const selectedDate = [range.startDate, range.endDate];
      this.date = selectedDate;
    }
  },
  watch: {
    filtersOpen: {
      immediate: true,
      handler(newValue, oldValue) {
        this.setDateForInput();
        this.filters.nights = this.variables.nights;
      }
    }
  },
  mounted() {},
  created: function () {}
};
</script>
