<template>
  <div>
    <span class="search__results__filter__title">{{
      $t("searchFilters.starCount")
    }}</span>
    <span
      class="c-button--filter--stars"
      :class="{ selected: stars.oneStars }"
      @click="toggleStarsFilter('oneStars')"
    >
      1

      <i class="fas fa-star"></i>
    </span>
    <span
      class="c-button--filter--stars"
      :class="{ selected: stars.twoStars }"
      @click="toggleStarsFilter('twoStars')"
    >
      2
      <i class="fas fa-star"></i>
    </span>
    <span
      class="c-button--filter--stars"
      :class="{ selected: stars.threeStars }"
      @click="toggleStarsFilter('threeStars')"
    >
      3
      <i class="fas fa-star"></i>
    </span>
    <span
      class="c-button--filter--stars"
      :class="{ selected: stars.fourStars }"
      @click="toggleStarsFilter('fourStars')"
    >
      4
      <i class="fas fa-star"></i>
    </span>
    <span
      class="c-button--filter--stars"
      :class="{ selected: stars.fiveStars }"
      @click="toggleStarsFilter('fiveStars')"
    >
      5
      <i class="fas fa-star"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      stars: {
        oneStars: false,
        twoStars: false,
        threeStars: false,
        fourStars: false,
        fiveStars: false
      }
    };
  },
  emits: ["starsChanged"],
  methods: {
    toggleStarsFilter(star) {
      this.stars[star] = !this.stars[star];
      this.$emit("starsChanged", this.stars);
    }
  }
};
</script>

<style lang="scss" scoped></style>
