<template>
  <div>
    <form @submit.prevent="createUnlistedEmployee()">
      <h5 class="title is-5">
        <template v-if="!isFlights">
          {{ $t("ReservationForm.addAGuest") }}
        </template>
        <template v-else>
          {{ $t("ReservationForm.addAPassenger") }}
        </template>
      </h5>
      <br />
      <label for="firstName">{{ $t("ReservationForm.firstname") }}</label>
      <div class="control">
        <input
          class="input"
          name="firstName"
          id="firstName"
          v-model="firstName"
          required
          type="text"
        />
      </div>

      <label for="lastName">{{ $t("ReservationForm.lastname") }}</label>
      <div class="control">
        <input
          class="input"
          name="lastName"
          id="lastName"
          v-model="lastName"
          required
          type="text"
        />
      </div>

      <label for="emailAddress">{{ $t("ReservationForm.emailAddress") }}</label>
      <div class="control">
        <input
          class="input"
          v-model="emailAddress"
          required
          autocomplete="email"
          name="emailAddress"
          id="emailAddress"
          type="email"
        />
      </div>
      <div class="is-justify-content-right is-flex">
        <button class="button is-success is-small mt-4">
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
          <span> {{ $t("add") }} </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    isFlights: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { firstName: "", lastName: "", emailAddress: "" };
  },
  methods: {
    createUnlistedEmployee() {
      const unlistedEmployee = {
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        // Magic! (for removing)
        id: Math.floor(Math.random() * 99383)
      };
      this.firstName = "";
      this.lastName = "";
      this.emailAddress = "";
      this.track("hotelReservationGuestAdd", {
        additionType: "manual"
      });
      this.$emit("createdUnlistedEmployee", { employee: unlistedEmployee });
    }
  }
};
</script>

<style lang="scss" scoped></style>
