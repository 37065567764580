<template>
  <div>
    <input type="text" id="autocomplete-input" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.initAutocomplete();
  },
  methods: {
    initAutocomplete() {
      const center = { lat: 52.4846582, lng: 5.9579199 };

      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 20,
        south: center.lat - 20,
        east: center.lng + 20,
        west: center.lng - 20
      };
      const input = document.getElementById("autocomplete-input");
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: [] },
        language: "nl",
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["geocode", "establishment"]
      };
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(input, options);
    }
  }
};
</script>
