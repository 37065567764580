import axios from "axios";
import CommonHelper from "@/services/common.helper.js";
import moment from "moment";
let today = moment();

const getReservations = async (fromDate, toDate) => {
  const url = `${CommonHelper.getApiURL()}/reservation/overview?fromDate=${fromDate}&toDate=${toDate}`;

  const { data, status } = await axios.get(url);
  if (status == 200) {
    let reservations = data.reservations ?? [];
    reservations.forEach(setCancellationProps);
    return reservations;
  } else {
    return null;
  }
};
const cancelReservations = async (dateIds) => {
  const url = `${CommonHelper.getApiURL()}/reservation/cancel`;
  const { data } = await axios.post(url, dateIds);
  return data;
};
const setCancellationProps = (reservation) => {
  let dates = getReservationDates(reservation);
  const cancelledDates = dates.filter(dateIsCancelled).length;

  const cancellableDates = dates.filter(dateIsCancellable).length;

  reservation.hasCancelledDates = cancelledDates > 0;
  reservation.hasCancallabeDates = cancellableDates > 0;
  reservation.allDatesCancelled = dates.length == cancelledDates;
  reservation?.guestsWithDates?.forEach((gwd) => {
    let guestHasCancallableDates = false;
    let guestHasCancelledDates = false;
    let allDatesCancelled = true;
    gwd.dates.forEach((d) => {
      if (dateIsCancellable(d)) {
        guestHasCancallableDates = true;
      }
      if (dateIsCancelled(d)) {
        guestHasCancelledDates = true;
      } else {
        allDatesCancelled = false;
      }
    });
    if (gwd.employee === null) gwd.employee = {};
    if (gwd.employee.id === null) {
      gwd.employee.id = Math.random().toString(36).substr(2, 9);
    }
    if (gwd.employee.firstName === null) {
      gwd.employee.firstName = "";
    }
    if (gwd.employee.lastName === null) {
      gwd.employee.lastName = "";
    }

    gwd.employee.hasCancallabeDates = guestHasCancallableDates;
    gwd.employee.hasCancelledDates = guestHasCancelledDates;
    gwd.employee.allDatesCancelled = allDatesCancelled;
  });
};
const getReservationDates = (reservation) => {
  const guestsWithDates = reservation.guestsWithDates;
  let dates = guestsWithDates
    .map((gwd) => {
      return gwd.dates;
    })
    .flat();
  return dates;
};
const dateIsCancelled = (d) => {
  let isCancelledDate =
    d.status != "" &&
    (d.status.toLowerCase().includes("cancel") ||
      d.status.toLowerCase().includes("rejected"));

  return isCancelledDate;
};
const dateIsCancellable = (d) => {
  let isCancelledDate =
    d.status != "" &&
    (d.status.toLowerCase().includes("cancel") ||
      d.status.toLowerCase().includes("rejected"));

  let isInFuture = moment(d.date).isAfter(today);
  let isCancallabeDate = !isCancelledDate && isInFuture;

  return isCancallabeDate;
};
export default {
  getReservations,
  cancelReservations,
  setCancellationProps,
  dateIsCancellable
};
