<template>
  <div class="details__content__box details__content__box--review-detail">
    <ReviewsSummary
      :score="content.reviewScoreOverall"
      :amount="content.numberOfReviews"
    />
    <span>{{ $t("reviews.excellent") }}</span>
    <div class="c-review-bar">
      <div
        class="c-review-bar--filler"
        :style="`width:${percentages.excellent}%`"
      ></div>
      <span class="c-review-bar--label"> {{ percentages.excellent }}% </span>
    </div>
    <span>{{ $t("reviews.veryGood") }}</span>
    <div class="c-review-bar">
      <div
        class="c-review-bar--filler"
        :style="`width:${percentages.veryGood}%`"
      ></div>
      <span class="c-review-bar--label"> {{ percentages.veryGood }}% </span>
    </div>
    <span>{{ $t("reviews.average") }}</span>
    <div class="c-review-bar">
      <div
        class="c-review-bar--filler"
        :style="`width:${percentages.average}%`"
      ></div>
      <span class="c-review-bar--label"> {{ percentages.average }}% </span>
    </div>
    <span>{{ $t("reviews.poor") }}</span>
    <div class="c-review-bar">
      <div
        class="c-review-bar--filler"
        :style="`width:${percentages.poor}%`"
      ></div>
      <span class="c-review-bar--label"> {{ percentages.poor }}% </span>
    </div>

    <span>{{ $t("reviews.terrible") }}</span>
    <div class="c-review-bar">
      <div
        class="c-review-bar--filler"
        :style="`width:${percentages.terrible}%`"
      ></div>
      <span class="c-review-bar--label"> {{ percentages.terrible }}% </span>
    </div>
  </div>
</template>

<script>
import ReviewsSummary from "@/components/hoteldetail/ReviewsSummary.vue";

export default {
  components: {
    ReviewsSummary
  },
  data() {
    return {
      percentages: {}
    };
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.percentages = this.getPercentages();
  },
  methods: {
    getPercentages() {
      const totalReviews = this.content.numberOfReviews;
      const calculatedTotalRatings =
        this.content.ratingTerrible +
        this.content.ratingPoor +
        this.content.ratingAverage +
        this.content.ratingVeryGood +
        this.content.ratingExcellent;
      const getPercentage = (reviewCount) => {
        const percentage = reviewCount / (calculatedTotalRatings / 100);

        return Math.round(percentage);
      };
      let percentages = {
        terrible: getPercentage(this.content.ratingTerrible),
        poor: getPercentage(this.content.ratingPoor),
        average: getPercentage(this.content.ratingAverage),
        veryGood: getPercentage(this.content.ratingVeryGood),
        excellent: getPercentage(this.content.ratingExcellent)
      };

      return percentages;
    }
  }
};
</script>

<style lang="scss" scoped>
.c-review-bar {
  display: block;
  width: 100%;
  height: 1.1rem;
  background: #f1f1f1;
  margin-bottom: 15px;
  position: relative;

  &--filler {
    height: 1rem;
    position: absolute;
    z-index: 1;

    background-color: orange;
  }
  &--label {
    font-size: 0.8rem;
    width: 100%;
    text-align: right;
    display: block;
    padding-right: 10px;
  }
}
.details__content__box--review-detail .details__content__score {
  float: none;
  margin-bottom: 15px;
}
</style>
