<template>
  <div>
    <span class="search__results__filter__title">{{
      $t("amenities.label")
    }}</span>
    <div class="limited-amenities">
      <span
        class="checkbox--filter"
        v-for="(value, amenity) in amenities"
        :key="amenity"
      >
        <input
          :id="'filter-' + amenity"
          v-model="amenities[amenity]"
          type="checkbox"
        />

        <label :for="'filter-' + amenity" class="checkbox--filter--label">{{
          $t("expedia." + amenity)
        }}</label>
      </span>
    </div>
  </div>
</template>

<script>
import FilterHelper from "@/services/filters.helper.js";

export default {
  props: {},
  data() {
    return {
      amenities: {}
    };
  },
  created() {
    FilterHelper.getPrimeAmenities().forEach(
      (a) => (this.amenities[a.name] = false)
    );
  },
  emits: ["amenitiesChanged"],
  watch: {
    amenities: {
      handler(newValue, oldValue) {
        this.$emit("amenitiesChanged", this.amenities);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.limited-amenities {
  max-height: 270px;
  overflow-y: scroll;
}
</style>
