<template>
  <div>
    <span class="search__results__filter__title">{{
      $t("greenKeyFilter.header")
    }}</span>
    <span class="checkbox--filter">
      <input id="filter-greenkey" v-model="greenKey" type="checkbox" />
      <label for="filter-greenkey" class="checkbox--filter--label">{{
        $t("greenKeyFilter.label")
      }}</label>
    </span>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      greenKey: false
    };
  },
  emits: ["greenKeyChanged"],
  watch: {
    greenKey: {
      handler(newValue, oldValue) {
        this.$emit("greenKeyChanged", this.greenKey);
      },
      deep: true
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
