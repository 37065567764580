<template>
  <div class="mb-4">
    <h6 class="title is-6" v-html="$t(title)"></h6>
    <p v-html="getContent"></p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    contentList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getContent() {
      if (!this.contentList || !this.contentList.length) {
        console.log("not content found for", this.title);
        return "";
      }
      if (this.contentList[0].indexOf("<ul>") > -1) {
        return this.contentList[0];
      } else {
        return `<ul><li>${this.contentList?.join("</li><li>")}</li></ul>`;
      }
    }
  }
};
</script>

<style lang="sass" scoped></style>
